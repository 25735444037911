const main = {
    TITLE: "آموزش حرفه ای آفیس",
    HONOR: "افتخار ما آموزش بیش از {student} هنرجو است",
    ADVANTAGES: "مقدماتی تا پیشرفته | بروزرسانی مداوم",
    COPY_RIGHT: "تمامی حقوق برای گروه نگاه محفوظ است و انتشار آموزشها مجاز نمی باشد",
    MAIN_CATEGORY_TAB: "آموزش های جامع",
    PACKAGES_TAB: "دوره های آموزشی",
    MORE_TAB: "بیشتر...",
    LESSON_COUNT: "تعداد جلسات: ",
    UPDATE_DATE: "بروزرسانی: ",
    SHOW_EDUCATION: "مشاهده آموزش ها",
    SOON: "به زودی",
    BUY_PACKAGES: "مشاهده جزئیات / خرید",
    STUDENT_COUNT:"تعداد کل هنرجویان",
    SEEN_COUNT:"تعداد نمایش ویدئوها",
    STUDENT:" هنرجو",
    NO_INTERNET:"لطفا دسترسی به اینترنت را بررسی نمایید"
}

export default main;