<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="pwa">
    <div class="justify-content-center pt-3 pb-3">
      <div class="all-card-size mx-auto">
        <p class="offcanvas-title text-center purple-text mt-2 mb-2" id="offcanvasBottomLabel">
          {{ FA_LAN.PWA.BANNER_DES }}</p>
        <div class="mx-auto text-center mb-4">
          <button type="button" class="btn btn-success btn-sm me-4" @click="install">{{ FA_LAN.PWA.INSTALL_NOW }}
          </button>
          <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-dismiss="offcanvas" @click="disMissBanner">
            {{ FA_LAN.PWA.DENY }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import {onMounted, ref} from "vue";
import {sweetAlert} from "@/js/alert/sweetAlert";

export default {
  name: "PWABanner",
  setup() {
    const FA_LAN = persian;
    const installPromptEvent = ref(null);

    onMounted(() => {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault(); //dont show automatic banner in old browser

        if (checkDeviceIsMobile() && !checkPWAInstalled()) {
          installPromptEvent.value = e; //add event to variable for use

          showBanner();
        }
      })
    })

    function install() {
      installPromptEvent.value.prompt(); //install pwa

      //get user action
      installPromptEvent.value.userChoice.then((choiceResult) => {

        if (choiceResult.outcome === 'accepted') {
          saveInstalledPwa();
          sweetAlert('success', FA_LAN.PWA.INSTALL_SUCCESS)
        } else {
          sweetAlert('warning', FA_LAN.PWA.INSTALL_FAIL)
        }

        //empty variable after user choice his action,it used and we can use again
        installPromptEvent.value = null;

        disMissBanner()
      })

    }

    function disMissBanner() {
      const app = document.getElementById("pwa");
      app.style.visibility = "hidden";
    }

    function showBanner() {
      const app = document.getElementById("pwa");
      app.classList.add('show');
      app.style.visibility = "visible";
      app.setAttribute("role", "dialog");
      app.setAttribute("aria-modal", "true")
    }

    function checkDeviceIsMobile() {
      let isMobile = false; //initiate as false

      // device detection
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true
      }

      return isMobile;
    }

    function saveInstalledPwa() {
      localStorage.setItem('pwaInstall', JSON.stringify(true));
    }

    function checkPWAInstalled() {
      return localStorage.getItem('pwaInstall');
    }

    return {FA_LAN, install, disMissBanner}
  }
}
</script>

<style scoped>

</style>