<template>
  <MainCategories v-if="isCategory" :code="code"/>
  <Packages v-else :code="code"/>
  <SummaryReview :code="code"/>
</template>

<script>
import {useRoute} from "vue-router";
import MainCategories from "../components/educations/MainCategories";
import Packages from "../components/educations/Packages";
import SummaryReview from "@/components/SummaryReview";

export default {
  name: "EducationList",
  components: {SummaryReview, Packages, MainCategories},
  setup() {
    const router = useRoute();
    const code = router.params.code;
    const isCategory = router.params.type === 'categories';

    return {isCategory, code}
  }
}
</script>

<style scoped>

</style>