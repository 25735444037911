// import {post} from "../mainRoute";

import {get, post} from "../mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {makeRandomString} from "../../js/protect/encodeVideoId";

function saveApiKeyLocal(key) {
    let newKey = '';
    if (key) {
        newKey = makeRandomString(9) + key + makeRandomString(5)
    }
    localStorage.setItem('apiKey', newKey)
}


function getApiKeyLocal() {
    const localKey = localStorage.getItem('apiKey')
    if (localKey) {
        return localKey.substr(9, 32);
    } else {
        return false;
    }
}

export const apiKey = {
    namespaced: true,
    state: {
        apiKey: getApiKeyLocal() ? getApiKeyLocal() : '',
        codeSend: localStorage.getItem('codeSent') ? localStorage.getItem('codeSent') : false,
        codeValid: localStorage.getItem('code') ? JSON.parse(localStorage.getItem('code')) : null,
        phone: localStorage.getItem('phone') ? localStorage.getItem('phone') : '',
    },
    mutations: {
        set(state, key) {
            state.apiKey = key
            saveApiKeyLocal(state.apiKey)
        },
        setCodeSent(state, phone) {
            const nowTime = new Date();
            nowTime.setMinutes(nowTime.getMinutes() + 2); //2 min left

            state.codeValid = nowTime;
            state.phone = phone;
            state.codeSend = true;

            localStorage.setItem('code', JSON.stringify(state.codeValid));
            localStorage.setItem('codeSent', state.codeSend);
            localStorage.setItem('phone', state.phone);
        },
        remove(state) {
            state.apiKey = ''
            state.phone = ''
            saveApiKeyLocal(state.apiKey);
            localStorage.setItem('phone', state.phone);
        }
    },
    getters: {
        get(state) {
            return state.apiKey
        },
        getCodeValid(state) {
            if (state.codeSend) {
                const nowTime = new Date();
                const codeDate = new Date(state.codeValid)

                if (codeDate < nowTime && state.codeValid) {
                    return false;
                }
            }

            return state.codeSend;
        },
        getPhone(state) {
            return state.phone
        }
    },
    actions: {
        async checkOtp(context, otp) {
            await get(`v1/otp/checkOtp?phone=${context.state.phone}&otp=${otp}`)
                .then(function (response) {
                    if (response.data.code === 200) {
                        context.commit('set', response.data.data.apiKey);
                    } else {
                        sweetAlert('error', response.data.description)
                    }
                })
                .catch(function (error) {
                    sweetAlert('error', error)
                })
        },
        async checkLogin(context) {
            await post('v1/checkLogin', null)
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)
                        context.commit('remove');
                    }
                })
                .catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}