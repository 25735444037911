<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.MORE.MY_EDUCATION"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <p v-if="!myEducations">{{ FA_LAN.BODY.EMPTY_MY_EDUCTION }}</p>
      <div v-else v-for="(buy,index) in myEducations" :key="index" class="mt-3 p-2  bg-white shadow-sm rounded">
        <p>{{ buy.main_category_title }}- {{ getSubTitle(buy) }}</p>
        <div class="d-flex justify-content-between">
          <p class="m-0 text-muted small">{{ withComma(buy.amount) }}{{ FA_LAN.PLANS.TOMAN }}</p>
          <p class="m-0 text-muted small ltr">{{ toJalali(buy.created_at) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import PageHeader from "@/components/PageHeader";
import {onMounted, ref} from "vue";
import {post} from "@/store/mainRoute";
import {numberWithCommas} from "@/js/number";
import {gregorian_to_jalali} from "@/js/date/convert";


export default {
  name: "MyPlans",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian;
    const myEducations = ref(null);

    onMounted(async () => {
      await fetch();
    })

    async function fetch() {
      await post('v1/getBuyList', null)
          .then(function (response) {
            if (response.data.code === 200) {
              myEducations.value = response.data.data.detail
            }
          })
    }

    function withComma(number) {
      return numberWithCommas(number)
    }

    function getSubTitle(buy) {
      return buy.sub_category_title ? buy.sub_category_title : buy.education_number;
    }

    function toJalali(date) {
      if (date) {
        return gregorian_to_jalali(date);
      }
    }

    return {FA_LAN, myEducations, withComma, getSubTitle, toJalali}
  }
}
</script>

<style scoped>
.ltr {
  direction: ltr;
}
</style>