import {post} from "../mainRoute";

export const summaryReports = {
    namespaced: true,
    state: {
        reports: localStorage.getItem('report') ? JSON.parse(localStorage.getItem('report')) : {
            student_count: 0,
            seen: 0
        },
    },
    mutations: {
        set(state, {student_count, total_seen}) {
            state.reports = {
                student_count: student_count,
                seen: total_seen
            }

            localStorage.setItem('report', JSON.stringify(state.reports))
        },
    },
    getters: {
        getSeen(state) {
            return state.reports.seen
        },
        getStudentCount(state) {
            return state.reports.student_count
        }
    },
    actions: {
        async fetch(context) {
            await post('v1/getSummaryReport', null)
                .then(function (response) {
                    if (response.data.code === 200) {
                        context.commit('set', response.data.data);
                    }
                })
        }
    }
}