import {createApp} from 'vue'
import App from './App.vue'

import router from "./router";
import store from "./store";
import {VueReCaptcha} from 'vue-recaptcha-v3';
import VueGtag from "vue-gtag";

import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './css/style.css';
import './registerServiceWorker'

import {isDesktop} from "@/swichDevice";

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueReCaptcha, {
    siteKey: '6Ld_zAgbAAAAACVNEBaklS8s2LeEUg_xTnrUMrrx',
    loaderOptions: {autoHideBadge: true}
})

if (!isDesktop) {
    app.use(VueGtag, {
        config: {
            id: "G-0YQC6S16S1", params: {
                send_page_view: false
            }
        }
    });
}

app.mount('#app')
