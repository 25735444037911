<template>
  <div>
    <div v-if="!loading">
      <p class="mt-3 mb-0">{{ FA_LAN.CRYPTO.PAY_INFORMATION }}</p>
      <div class="mt-2 p-3 bg-white shadow-sm rounded text-center">
        <p>{{ getPayAmountText() }}</p>
        <div class="row align-items-center">
          <div class="col-10"><h6 class="orange-text m-0">{{ payDetail.pay_amount }}</h6></div>
          <div class="col-2">
            <button class="btn btn-link text-decoration-none" @click="copy(payDetail.pay_amount)">
              <img class="copy" src="../../assets/icons/copy.png">
            </button>
          </div>
        </div>
      </div>
      <div class="mt-2 p-3 bg-white shadow-sm rounded text-center">
        <p>{{ getWalletText() }}</p>
        <div class="row align-items-center">
          <div class="col-10"><p class="orange-text m-0">{{ payDetail.pay_address }}</p></div>
          <div class="col-2">
            <button class="btn btn-link text-decoration-none" @click="copy(payDetail.pay_address)">
              <img class="copy" src="../../assets/icons/copy.png">
            </button>
          </div>
        </div>
      </div>
      <div>
        <p class="mt-3">{{ FA_LAN.CRYPTO.NOTICE }}</p>
        <p class="small secondary_text">{{ FA_LAN.CRYPTO.NOTE_P2_1 }}</p>
        <p class="small secondary_text">{{ FA_LAN.CRYPTO.NOTE_P2_2 }}</p>
        <p class="small secondary_text">{{ FA_LAN.CRYPTO.NOTE_P2_3 }}</p>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-5 col-12">
        <button class="btn blue-background text-white col-md-8 col-12" data-bs-toggle="offcanvas"
                data-bs-target="#cryptoPayed" aria-controls="offcanvasBottom">
          {{ FA_LAN.CRYPTO.PAYED_SUBMIT }}
        </button>
      </div>
      <CryptoPayed :order-id="payDetail.order_id"/>
    </div>
    <Loading :loading="loading"/>
  </div>

</template>

<script>
import persian from "@/fa-IR/fa-ir";
import Loading from "@/components/Loading";
import {onMounted, reactive, ref} from "vue";
import {post} from "@/store/mainRoute";
import {sweetAlert} from "@/js/alert/sweetAlert";
import CryptoPayed from "@/components/convas/CryptoPayed";
import {platForm} from "@/swichDevice";

export default {
  name: "PayInformation",
  props: ['planId','type', 'currency', 'planDetail'],
  components: {CryptoPayed, Loading},
  setup(props) {
    const FA_LAN = persian;
    const loading = ref(true);
    const payDetail = ref(null);
    const data = reactive({
      type: props.type,
      id: props.planId,
      market: platForm,
      currency: props.currency
    })

    onMounted(async () => {
      await getPlanDetail();
    })

    async function getPlanDetail() {
      await post('v1/crypto/createPay', data)
          .then(function (response) {
            if (response.data.code === 200 || response.data.code === 201) {
              payDetail.value = response.data.data.pay_information

              if (response.data.code === 201) {
                sweetAlert('warning', response.data.description)
              }
            }
          })

      loading.value = false;
    }

    function getPayAmountText() {
      return FA_LAN.CRYPTO.PAY_AMOUNT.replace("{currency}",
          payDetail.value.currency_detail.name_fa + " - " + payDetail.value.currency_detail.symbol)
    }

    function getWalletText() {
      return FA_LAN.CRYPTO.WALLET_ADDRESS.replace("{currency}",
          payDetail.value.currency_detail.name_fa + " - " + payDetail.value.currency_detail.symbol)
    }

    function copy(value) {
      navigator.clipboard.writeText(value).then(function() {
        sweetAlert('success',FA_LAN.CRYPTO.COPY_SUCCESS)
      }, function() {
        sweetAlert('success',FA_LAN.CRYPTO.COPY_FAIL)
      });
    }

    return {FA_LAN, loading, payDetail, getPayAmountText, getWalletText, copy}
  }
}
</script>

<style scoped>
.copy {
  max-width: 25px;
}
</style>