<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.WALLET.WALLET_TITLE"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <Loading :loading="loading" v-if="loading"/>
      <div v-else ref='scrollComponent'>
        <router-link
            class="mt-2 mb-3 pt-2 pb-2 ps-3 pe-3 text-decoration-none text-body d-flex justify-content-between
             warning-box-style shadow-sm align-items-center"
            :to="{name:'walletRule'}">
          {{ FA_LAN.WALLET.WHAT_IS_WALLET }}
          <img class="icon" src="../assets/icons/info-red.png">
        </router-link>
        <div class="mt-2 mb-2 d-flex justify-content-between bg-white shadow-sm rounded">
          <div><p class="m-0 p-3"> {{ FA_LAN.WALLET.WALLET_AMOUNT }} </p></div>
          <div>
            <p class="m-0 p-3 orange-text">{{ withComma(walletAmount) }} {{ FA_LAN.PLANS.TOMAN }}</p>
          </div>
        </div>
        <p class="mt-3 mb-0">{{ FA_LAN.WALLET.TRANSACTION_LIST }}</p>
        <p v-if="empty">{{ FA_LAN.WALLET.EMPTY_MY_WALLET }}</p>
        <div v-else v-for="transaction in walletTransaction" :key="transaction.id"
             class="mb-3 p-3 bg-white shadow-sm rounded">
          <div class="d-flex justify-content-between">
            <div>
              <p class="mb-2">{{ transaction.description }}</p>
              <p class="m-0 text-muted small ltr">{{ toJalali(transaction.created_at) }}</p>
            </div>
            <div class="text-center">
              <div class="off-status-style pt-1 pb-1 ps-3 pe-3 mb-1" :class="getStatusStyle(transaction.is_increase)">
                <small> {{ transaction.type_title }}</small>
              </div>
              <p class="m-0 text-muted small">{{ withComma(transaction.amount) }}{{ FA_LAN.PLANS.TOMAN }}</p>
            </div>
          </div>
        </div>
        <Loading :loading="loading"/>
        <button class="btn float-button blue-background text-white rounded-pill"
                data-bs-toggle="offcanvas" data-bs-target="#giftCode" aria-controls="offcanvasBottom">
          <span class="fs-5">+</span>
        </button>
        <GiftCode @refresh="refresh"/>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import persian from "@/fa-IR/fa-ir";
import {onMounted, onUnmounted, ref} from "vue";
import Loading from "@/components/Loading";
import {post} from "@/store/mainRoute";
import {sweetAlert} from "@/js/alert/sweetAlert";
import {numberWithCommas} from "@/js/number";
import {gregorian_to_jalali} from "@/js/date/convert";
import GiftCode from "@/components/convas/GiftCode";
import {useStore} from "vuex";

export default {
  name: "Wallet",
  components: {GiftCode, PageHeader, Loading},
  setup() {
    const FA_LAN = persian;
    const loading = ref(true);
    const walletAmount = ref(0);
    const walletTransaction = ref([]);
    const empty = ref(true);
    const index = ref(0);
    const scrollComponent = ref(null);
    const store = useStore();

    onMounted(async () => {
      await getWalletTransaction();

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    async function getWalletTransaction() {
      loading.value = true

      await post(`v1/wallet/getUserTransactionList?index=${index.value}`, null).then(function (response) {
        if (response.data.code === 200) {

          walletAmount.value = response.data.data.total;
          walletTransaction.value.push(...response.data.data.transaction_list);

        } else {
          sweetAlert('error', response.data.description)
        }
      })
          .catch(function (error) {
            sweetAlert('error', error)
          })

      checkEmpty();
      loading.value = false
    }

    function checkEmpty() {
      empty.value = walletTransaction.value.length === 0;
    }

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return;
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - walletTransaction.value.length > 10) { //for stop looping
          return;
        }

        index.value += 10;
        await getWalletTransaction();
      }
    }

    function checkPreviousReceive() {
      return walletTransaction.value.length === index.value + 10;
    }

    function withComma(number) {
      if (number === null || number === undefined) {
        return 0
      }
      return numberWithCommas(number)
    }

    function toJalali(date) {
      if (date) {
        return gregorian_to_jalali(date);
      }
    }

    function getStatusStyle(status) {
      return status ? 'purple-text' : 'orange-text'
    }

    async function refresh(ok) {
      if (!ok) {
        return;
      }

      loading.value = true;
      index.value = 0;
      walletTransaction.value = [];
      await store.dispatch('wallet/fetch');
      await getWalletTransaction();
    }

    return {
      FA_LAN,
      loading,
      empty,
      scrollComponent,
      walletTransaction,
      walletAmount,
      refresh,
      toJalali,
      withComma,
      checkEmpty,
      getStatusStyle
    }
  }
}
</script>

<style scoped>
.icon {
  width: 30px;
  height: auto;
}

.ltr {
  direction: ltr;
}
</style>