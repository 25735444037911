<template>
  <div>
    <div class="navbar blue-background text-white d-flex justify-content-center sticky-top pt-3">
      <button v-if="showBack()" class="btn btn-link text-decoration-none text-white" @click="back">
        <i class="bi bi-arrow-right pe-2"></i>
      </button>
      <div class="text-center">
        <div class="text-end small pb-2"><small>{{ FA_LAN.PLANS.NO_DATE_LIMIT }}</small></div>
        <h4> {{ FA_LAN.PLANS.TITLE }}</h4>
        <hr class="blue-light-text">
        <small>{{ FA_LAN.PLANS.DESCRIPTION }}</small>
        <div class="mt-3">
          <router-link class="buy-count-style text-decoration-none ps-2 pe-2 pt-1 pb-1" :to="{name:'wallet'}">
            <small>{{ FA_LAN.WALLET.USABLE_CREDIT }}{{ withComma(walletAmount) }}{{ FA_LAN.PLANS.TOMAN }}</small>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <Loading :loading="loading"/>
      <div v-for="plan in plans" :key="plan.id">
        <div v-if="checkCode(plan.main_category)" class="mt-3 pt-2 ps-2 bg-white shadow-sm rounded">
          <div class="row p-0 m-0">
            <div class="col-12">
              <p class="mb-2">
                {{ plan.main_category_title }}
                <span class="secondary_text"> {{ plan.sub_category_title }}</span>
              </p>
            </div>
            <div class="col-12 d-flex small">
              <p>
                <span class="secondary_text">{{ FA_LAN.PLANS.MAIN_PRICE }}</span>
                <span class="secondary_text text-decoration-line-through">
                {{ withComma(plan.base_amount) }}{{ FA_LAN.PLANS.TOMAN }}
                </span>
              </p>
              <p class="ms-2">
                <span class="orange-text" v-if="plan.amount!==0">
                {{ FA_LAN.PLANS.OFF }}{{ withComma(plan.amount) }}{{ FA_LAN.PLANS.TOMAN }}
              </span>
                <span class="orange-text" v-else>
               {{ FA_LAN.PLANS.OFF }} {{ FA_LAN.PLANS.FREE }}
              </span>
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-end pe-0">
            <button v-if="checkLogin()" class="btn btn-sm purple-background text-white" data-bs-toggle="offcanvas"
                    :data-bs-target="'#payChoose'+plan.id" aria-controls="offcanvasBottom">
              <small> {{ FA_LAN.PLANS.BUY }}</small>
            </button>
            <router-link v-else class="btn btn-sm purple-background text-white" :to="{name:'login'}">
              <small> {{ FA_LAN.PLANS.BUY }}</small>
            </router-link>
            <PayChoose :plan-id="plan.id" type="plan"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {post} from "../store/mainRoute";
import Loading from "../components/Loading";
import {sweetAlert} from "../js/alert/sweetAlert";
import {numberWithCommas} from "../js/number";
import PayChoose from "@/components/convas/PayChoose";
import {isDesktop} from "@/swichDevice";

export default {
  name: "Plans",
  components: {PayChoose, Loading},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const route = useRoute();
    const loading = ref(true);
    const plans = ref([]);
    const routeCode = ref(route.params.code);
    const checkKeyLenghth = ref(20);
    const router = useRouter();
    const walletAmount = computed(() => store.getters["wallet/get"]);

    onMounted(async () => {
      await checkOffApiKey();
      await getPlans()
    })

    function checkLogin() {
      return store.getters["apiKey/get"];
    }

    async function getPlans() {
      await post('v1/new/getPayPlans')
          .then(function (response) {
            if (response.data.code === 200) {
              plans.value = response.data.data.plans;
            } else {
              sweetAlert('error', response.data.description)
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
      loading.value = false;
    }

    function checkCode(code) {
      if (routeCode.value.length <= checkKeyLenghth.value) {
        return code === routeCode.value;
      } else {
        return true; //show all plan if apikey is instead code
      }
    }

    function withComma(number) {
      return numberWithCommas(number)
    }

    async function checkOffApiKey() {//only for off from bazar app
      if (routeCode.value.length > checkKeyLenghth.value) {
        store.commit("apiKey/set", routeCode.value);
        await store.dispatch("apiKey/checkLogin");
      }
    }

    function back() {
      router.back();
    }

    function showBack() {
      return isDesktop;
    }

    return {FA_LAN, loading, plans, walletAmount, checkCode, withComma, checkLogin, back, showBack}
  }
}
</script>

<style scoped>

</style>