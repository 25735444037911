<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.CRYPTO.PAGE_TITLE"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <CryptoPlanDetail v-if="planDetail" :plan-detail="planDetail"/>

      <ChooseCurrency v-if="!currency" :plan-id="planID" :type="type" @currency="setCurrency"
                      @planDetail="setPlanDetail"/>
      <PayInformation v-else :plan-id="planID" :type="type" :currency="currency"/>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import {useRoute} from "vue-router";
import PageHeader from "@/components/PageHeader";
import ChooseCurrency from "@/components/cryptoPay/ChooseCurrency";
import PayInformation from "@/components/cryptoPay/PayInformation";
import {ref} from "vue";
import CryptoPlanDetail from "@/components/cryptoPay/CryptoPlanDetail";

export default {
  name: "CryptoPay",
  components: {CryptoPlanDetail, PayInformation, ChooseCurrency, PageHeader},
  setup() {
    const FA_LAN = persian;
    const route = useRoute();
    const planID = route.params.planId;
    const type = route.params.type;
    const currency = ref(null)
    const planDetail = ref(null);

    function setCurrency(value) {
      currency.value = value
    }

    function setPlanDetail(value) {
      planDetail.value = value.value
    }

    return {FA_LAN, planID, currency, type, planDetail, setCurrency, setPlanDetail}
  }
}
</script>

<style scoped>

</style>