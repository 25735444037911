const profile = {
    TITLE: "تکمیل اطلاعات پروفایل",
    UPDATE: "ثبت اطلاعات",
    MALE: "آقای",
    FEMALE: "خانم",
    NAME: "نام و نام خانوادگی",
    EDUCATION: "تحصیلات",
    FIELD: "رشته تحصیلی",
    JOB: "شغل",
    BIRTHDAY: "تاریخ تولد",

    ERROR_SEX: "لطفا یک مورد را انتخاب کنید",
    ERROR_NAME: "لطفا نام خود را کامل وارد نمایید",
    ERROR_EDUCATION: "لطفا تحصیلات خود را وارد نمایید",
    ERROR_FIELD: "لطفا رشته تحصیلی خود را وارد کنید",
    ERROR_JOB: "لطفا شغل و حوزه کاری خود را کامل وارد نمایید",
    ERROR_BIRTHDAY: "لطفا تاریخ تولد خود را وارد نمایید",

}

export default profile