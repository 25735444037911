import {isDesktop} from "@/swichDevice";

export function convertID(oldUrl) {
    const splittedOldUrl = oldUrl.split('/');

    const oldUrlArrayLen = splittedOldUrl.length;

    const eduNumber = splittedOldUrl[oldUrlArrayLen - 1].split('.'); //last section, sample "1.mp4"

    const encodeID = encodeVideoId(eduNumber[0]);

    return encodeID;
}

export function convertURL(code, hashedId) {
    let newUrl;

    if (isDesktop) {
        newUrl = decodeVideoId(code, hashedId.substr(0, hashedId.length - 6));

    } else if (!safariDetection()) {
        const newUrlBase = 'https://dl.aliyavary.ir/office_ticket/public/playVideo/';
        newUrl = newUrlBase + code + "/" + hashedId.substr(0, hashedId.length - 6);

    } else {
        newUrl = decodeVideoId(code, hashedId.substr(0, hashedId.length - 6));
    }

    return newUrl;
}

function encodeVideoId(id) {
    const idNumbers = id.match(/.{1}/g);

    let encodedID = '';
    idNumbers.forEach(number => {
        encodedID += makeRandomString(1) + getChar(number);
    });

    const newID = makeRandomString(8) + encodedID + makeRandomString(6);

    return newID;
}

function decodeVideoId(code, hashed) {
    const oldUrlBase = 'https://dl.aliyavary.ir/office_education/';

    const baseCode = hashed.substr(8, hashed.length);

    let id;
    if (baseCode.includes('-')) {
        id = withPrefixDecode(baseCode)

    } else {
        id = simpleDecode(baseCode)
    }

    return oldUrlBase + code + "/" + id + ".mp4";
}

function simpleDecode(baseCode) {

    let id = '';

    const idChars = baseCode.match(/.{1,2}/g);
    idChars.forEach(char => {
        id += getNumber(char.substr(1, 1));
    });

    return id
}

function withPrefixDecode(baseCode) {
    const split = baseCode.split('-');

    let id = '';

    const chars = split[0].match(/.{1,2}/g);
    chars.forEach(char => {
        id += char.substr(1, 1);
    });

    id += '-';

    const idChars = split[1].match(/.{1,2}/g);
    idChars.forEach(char => {
        id += getNumber(char.substr(1, 1));
    });

    return id
}

export function makeRandomString(length) {
    var result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }

    return result;
}

function getChar(num) {
    if (isNumeric(num)) {
        num++; //0=>1
        return String.fromCharCode(96 + num);
    } else {
        return num;
    }

}

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function getNumber(char) {

    const number = char.charCodeAt(0);

    return number - 96 - 1;
}

function safariDetection() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    return iOSSafari;
}

export function encodeEducationID(id) {
    return id * 183402;
}

export function decodeEducationID(id) {
    return id / 183402;
}