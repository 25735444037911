<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.BODY.LOGIN"/>
    </div>
    <div class="container login-card-size">
      <div class="justify-content-center mt-5">
        <div class="card border-0 shadow-sm rounded">
          <div class="card-body">{{ FA_LAN.FORM.LOGIN_TITLE }}</div>
        </div>
      </div>
      <transition name="slide-fade">
        <ValidSms v-if="otpSent"/>
        <SendSms v-else/>
      </transition>
    </div>
  </div>
</template>

<script>
import SendSms from "../components/login/SendSms";
import ValidSms from "../components/login/ValidOtp";
import persian from "../fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed, onMounted, watch} from "vue";
import {useRouter} from "vue-router";
import PageHeader from "@/components/PageHeader";

export default {
  name: "Login",
  components: {ValidSms, SendSms, PageHeader},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();
    const otpSent = computed(() => store.getters["apiKey/getCodeValid"]);
    const apiKey = computed(() => store.getters["apiKey/get"]);

    watch(apiKey, () => {
      checkAPIKey();
    })

    onMounted(() => {
      checkAPIKey();
    })

    function checkAPIKey() {
      store.dispatch("groupPlan/fetch");

      if (apiKey.value) {
        router.back()
      }
    }

    return {FA_LAN, otpSent}
  }
}
</script>

<style scoped>

</style>