const cryptoPay = {
    PAGE_TITLE: "پرداخت با ارز دیجیتال",
    DOLLAR: " دلار",
    CHOOSE_CURRENCY: "با کدام ارز پرداخت می‌کنید؟",
    NOTICE: "نکات مهم",
    NOTE_P1_1: "۱- اگر با نحوه کار با ارز دیجیتال آشنایی ندارید، لطفاً از درگاه بانکی برای پرداخت استفاده کنید.",
    NOTE_P1_2: "۲- ارزها با کارمزد تراکنش پایین انتخاب شده و پشتیبانی می‌شوند.",
    NOTE_P1_3: "۳- بررسی کارمزد دقیق تراکنش ارز مورد نظر به عهده کاربر می‌باشد.",
    GET_PAY_INFORMATION: "دریافت اطلاعات پرداخت",
    CURRENCY_NOT_SELECTED: "ارز مورد نظر برای پرداخت انتخاب نشده است",
    PAY_INFORMATION: "اطلاعات پرداخت",
    PAY_AMOUNT: "مقدار {currency} جهت پرداخت",
    WALLET_ADDRESS: "آدرس کیف پول {currency} جهت پرداخت",
    NOTE_P2_1: "۱- لطفاً دقت فرمایید شبکه انتخابی، آدرس کیف پول و مبلغ پرداختی دقیقاً با اطلاعات نمایش داده شده برابر و منطبق باشد.",
    NOTE_P2_2: "۲- توجه کنید مبلغ بالا خالص واریزی شما می‌باشد و باید کارمزد تراکنش را مطابق با کیف پول خود در هنگام انتقال در نظر داشته باشید.",
    NOTE_P2_3: "۳- در صورت بروز هرگونه اشتباه توسط کاربر، به دلیل ماهیت ارزهای دیجیتال امکان بازگشت تراکنش وجود ندارد.",
    PAYED_SUBMIT: "پرداخت کردم",
    TANKS: "با تشکر از پرداخت شما",
    SUBMIT_DESCRIPTION: "بسته به شبکه و ارز انتخابی، تأیید تراکنش ممکن است نیم الی یک ساعت زمان نیاز داشته باشد. پس از تأیید تراکنش و فعال شدن دوره، از طریق پیامک به شما اطلاع رسانی خواهد شد",
    TRACKING: "در صورتی که بعد از ۵ ساعت دوره شما فعال نشد، جهت پیگیری با ارائه شناسه پیگیری پرداخت از طریق راه‌های ارتباطی (صفحه درباره ما) در ارتباط باشید",
    ORDER_ID: "شناسه پیگیری پرداخت",
    OK: "تأیید و بستن این صفحه",
    COPY_SUCCESS: "مقدار مورد نظر کپی شد",
    COPY_FAIL: "مروزگر شما این قابلیت را پشتیبانی نمی کند",
}

export default cryptoPay