<template>
  <div>
    <div class="mt-3">
      <div class="card border-0 shadow-sm rounded">
        <div class="card-body">
          <form>
            <div class="mb-3">
              <label class="form-label purple-text">{{ FA_LAN.FORM.PHONE }}</label>
              <input type="number" class="form-control input" v-model.lazy="phone">
              <div class="form-text text-danger" v-if="wrongPhone">{{ FA_LAN.FORM.WRONG_PHONE }}</div>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <button type="submit" class="btn btn-sm blue-background text-white  ps-5 pe-5 col-12"
                      @click.prevent="sendOtp" :disabled="loading">
                <div class="spinner-border spinner-border-sm text-white" role="status" v-if="loading"></div>
                {{ FA_LAN.FORM.CODE_SEND }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {ref} from "vue";
import {get, recaptchChek} from "../../store/mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {useStore} from "vuex";
import {isDesktop} from "@/swichDevice";
import {useReCaptcha} from "vue-recaptcha-v3";


export default {
  name: "SendSms",
  setup() {
    const FA_LAN = persian;
    const phone = ref('');
    const loading = ref(false);
    const wrongPhone = ref(false);
    const store = useStore();
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();
    const tokenValid = ref(false);

    async function sendOtp() {
      checkPhone();

      if (wrongPhone.value) {
        return
      }

      loading.value = true

      if (!isDesktop) {
        await recaptcha();

        if (!tokenValid.value) {
          loading.value = false;
          return;
        }
      }

      if (String(phone.value).length === 10) {
        phone.value = '0' + phone.value;
      }

      await get(`v1/otp/sendOtp?phone=${phone.value}`)
          .then(function (response) {
            if (response.data.code === 200) {
              store.commit('apiKey/setCodeSent', phone.value);
            } else {
              sweetAlert('error', response.data.description)
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
      loading.value = false
    }

    async function recaptcha() {

      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')

      // Do stuff with the received token.
      await recaptchChek(token)
          .then(function (response) {
            if (response.data.success === true && response.data.score >= .5) {
              tokenValid.value = true;

            } else {
              sweetAlert('error', FA_LAN.FORM.RECAPTCHA_ERROR)
              tokenValid.value = false;

            }
          })
    }

    function checkPhone() {
      const phoneNUmber = String(phone.value);
      const first = phoneNUmber.charAt(0);

      if ((first == '0' && phoneNUmber.length !== 11) || (first != '0' && phoneNUmber.length !== 10)) {
        wrongPhone.value = true;
      } else {
        wrongPhone.value = false;
      }
    }

    return {FA_LAN, phone, loading, wrongPhone, sendOtp}
  }
}
</script>

<style scoped>

</style>