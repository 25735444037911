const body = {
    //form
    LOGIN: 'ورود',
    DELETE: 'حذف',
    FILTER: 'فیلتر',
    SEARCH: 'جست و جو',
    LOAD_MORE: 'موارد بیشتر',
    SEND_RESPONSE: 'ارسال پاسخ',
    PAY_SUBMIT: 'ثبت پرداخت جدید',
    RECEIVING_DATA: 'دریافت اطلاعات',

    //separated
    EDUCATION_SUBMIT: 'ثبت آموزش جدید',
    CATEGORY_SUBMIT: 'ثبت دسته آموزش جدید',

    //
    EMPTY:"آموزشی پیدا نشد",

    EMPTY_MY_EDUCTION:"هنوز دوره ای تهیه نکردید",
}

export default body;