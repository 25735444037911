const plans={
    TITLE:"دریافت اشتراک آموزش ها",
    NO_DATE_LIMIT:"بدون تاریخ انقضا",
    DESCRIPTION:"ترافیک نیم بها | مقدماتی تا پیشرفته | بروزرسانی مداوم",
    MAIN_PRICE:"هزینه فعالسازی: ",
    OFF:"    با تخفیف: ",
    TOMAN:" تومان",
    BUY:"فعالسازی پکیج",
    FREE:"رایگان !!"
}

export default plans