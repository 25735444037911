<template>
  <div>
    <div class="d-flex justify-content-center mb-1">
      <div>
        <h6 class="m-0">{{ withComma(studentCount) }}<i class="bi bi-people-fill align-middle purple-text ms-2"></i>
        </h6>
        <p class="m-0 text-muted small">{{ FA_LAN.MAIN.STUDENT_COUNT }}</p>
      </div>
      <div class="ms-3 me-4 pt-3">
        <span class="ps-2 pe-2 ">{{ FA_LAN.BUTTON.BTN_SEPERATOR }}</span>
      </div>
      <div>
        <h6 class="m-0">{{ withComma(seen) }}<i class="bi bi-eye-fill align-middle blue-text ms-2"></i></h6>
        <p class="m-0 text-muted small">{{ FA_LAN.MAIN.SEEN_COUNT }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed} from "vue";
import {numberWithCommas} from "@/js/number";

export default {
  name: "SummaryReport",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const seen = computed(() => store.getters["summaryReports/getSeen"]);
    const studentCount = computed(() => store.getters["summaryReports/getStudentCount"]);

    function withComma(number) {
      return numberWithCommas(number)
    }

    return {FA_LAN, seen, studentCount, withComma}
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>