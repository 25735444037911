const off = {
    SEEN: "مشاهده کردم",
    AMOUNT: "میزان تخفیف: ",
    PERCENTAGE: "درصد",
    COUNT: "تعداد دفعات قابل استفاده: ",
    VALID_DATE: "معتبر تا تاریخ: ",
    AUTO_ASSIGN: "این تخفیف به صورت خودکار بر روی خریدهای شما اعمال می شود",

    //off page
    PAGE_TITLE: "تخفیف‌های من",
    EXPIRED: "منقضی شده",
    USED: "استفاده شده",
    ACTIVE: "فعال",
    PUBLIC: "عمومی",
    PRIVATE: "اختصاصی",
    CREATE_DATE: "از تاریخ: ",
    VALID_DATE_SIMPLY: "معتبر تا: ",
    COUNT_SIMPLY: "تعداد: ",
    PERCENTAGE_SIGN: "%",
    ACTIVE_OFF_APPLIED: "تخفیف‌های فعال به صورت اتوماتیک روی قیمت‌ها لحاظ شده‌اند"
}

export default off