<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.WALLET.WALLET_RULE_TITLE"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <Loading :loading="loading" v-if="loading"/>
      <div v-else>
        <p class="mt-3 mb-0">{{ FA_LAN.WALLET.WALLET_USE_RULE }}</p>
        <div class="mt-2 p-3 bg-white shadow-sm rounded text-muted">
          <p class="mb-2" v-for="(rule, index) in walletRules.use_rule" :key="index">
            {{ rule }}
          </p>
        </div>
        <p class="mt-3 mb-0">{{ FA_LAN.WALLET.WALLET_INCREASE_METHOD }}</p>
        <div class="mt-2 p-3 bg-white shadow-sm rounded text-muted">
          <p class="mb-2" v-for="(rule, index) in walletRules.increase_methods" :key="index">
            {{ rule }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import persian from "@/fa-IR/fa-ir";
import {onMounted, ref} from "vue";
import Loading from "@/components/Loading";
import {post} from "@/store/mainRoute";
import {sweetAlert} from "@/js/alert/sweetAlert";

export default {
  name: "WalletRule",
  components: {Loading, PageHeader},
  setup() {
    const FA_LAN = persian
    const loading = ref(true);
    const walletRules = ref([]);

    onMounted(() => {
      getWalletRules()
    })

    async function getWalletRules() {
      await post(`v1/wallet/getRules`, null).then(function (response) {
        if (response.data.code === 200) {
          walletRules.value = response.data.data.wallet_rules;

        } else {
          sweetAlert('error', response.data.description)
        }
      })
          .catch(function (error) {
            sweetAlert('error', error)
          })

      loading.value = false;
    }

    return {FA_LAN, loading, walletRules}
  }
}
</script>

<style scoped>

</style>