<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" id="giftCode" aria-labelledby="offcanvasBottomLabel">
    <div class="justify-content-center pt-3 pb-3">
      <div class="all-card-size mx-auto">
        <h5 class="offcanvas-title text-center purple-text" id="offcanvasBottomLabel">
          {{ FA_LAN.WALLET.ENTER_GIFT_CODE }}</h5>
        <hr class="text-center">
        <div class="text-center">
          <p>{{ FA_LAN.WALLET.GIFT_CODE_DESCRIPTION }}</p>
        </div>
        <form class="col-md-8 mx-auto">
          <div class="mb-3">
            <label class="form-label purple-text">{{ FA_LAN.WALLET.GIFT_CODE }}</label>
            <input type="text" class="form-control input" v-model="data.code">
            <div class="form-text text-danger" v-if="notEnter">{{ FA_LAN.WALLET.CODE_NOT_ENTER }}</div>
          </div>
          <div class="d-grid gap-2">
            <button class="btn btn blue-background text-white" @click.prevent="submit" :disabled="loading">
              <span class="spinner-border spinner-border-sm text-white" role="status" v-if="loading"></span>
              {{ FA_LAN.WALLET.SUBMIT_CODE }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import {reactive, ref, watch} from "vue";
import {post} from "@/store/mainRoute";
import {sweetAlert} from "@/js/alert/sweetAlert";

export default {
  name: "GiftCode",
  setup(props, {emit}) {
    const FA_LAN = persian;
    const loading = ref(false);
    const notEnter = ref(false);
    const data = reactive({
      code: ''
    })

    watch(data, () => {
      if (data.code.length !== 0) {
        notEnter.value = false
      }
    })

    function isCodeEntered() {
      return data.code.length !== 0;
    }

    async function submit() {

      if (!isCodeEntered()) {
        notEnter.value = true;
        return;
      }

      loading.value = true;
      await post(`v1/wallet/submitGiftCode`, data).then(function (response) {
        if (response.data.code === 200) {
          sweetAlert('success', response.data.description)
          emit('refresh', true)
          hide();

        } else {
          sweetAlert('error', response.data.description)
        }
      })
          .catch(function (error) {
            sweetAlert('error', error)
          })
      loading.value = false;
    }

    function hide() {
      const app = document.getElementById("giftCode");
      app.style.visibility = "hidden";
    }

    return {FA_LAN, data, loading, notEnter, submit}
  }
}
</script>

<style scoped>
input {
  direction: ltr;
}
</style>