import {post} from "../mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";

export const packages = {
    namespaced: true,
    state: {
        packages: []
    },
    mutations: {
        set(state, packages) {
            state.packages = packages
        }
    },
    getters: {
        all(state) {
            return state.packages
        },
        package: (state) => (code) => {
            return state.packages.find(p => p.code === code);
        }
    },
    actions: {
        async fetch(context) {
            await post('v1/getPackages', null).then(function (response) {
                if (response.data.code === 200) {
                    context.commit('set', response.data.data.packages)
                } else {
                    sweetAlert('error', response.data.description)
                }
            })
                .catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}