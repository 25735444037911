<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.PROFILE.TITLE"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <div class="card border-0 shadow-sm rounded p-3">
        <form>
          <div class="mb-4">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="male" v-model="data.sex">
              <label class="form-check-label purple-text">{{ FA_LAN.PROFILE.MALE }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="female" v-model="data.sex">
              <label class="form-check-label purple-text">{{ FA_LAN.PROFILE.FEMALE }}</label>
            </div>
            <div class="form-text text-danger" v-if="error.sex">{{ FA_LAN.PROFILE.ERROR_SEX }}</div>
          </div>
          <div class="mb-4">
            <label class="form-label purple-text mb-0">{{ FA_LAN.PROFILE.NAME }}</label>
            <input type="text" class="form-control input" required v-model="data.name">
            <div class="form-text text-danger" v-if="error.name">{{ FA_LAN.PROFILE.ERROR_NAME }}</div>
          </div>
          <div class="mb-4">
            <label class="form-label purple-text mb-0">{{ FA_LAN.PROFILE.EDUCATION }}</label>
            <input type="text" class="form-control input" required v-model="data.education">
            <div class="form-text text-danger" v-if="error.education">{{ FA_LAN.PROFILE.ERROR_EDUCATION }}</div>
          </div>
          <div class="mb-4">
            <label class="form-label purple-text mb-0">{{ FA_LAN.PROFILE.FIELD }}</label>
            <input type="text" class="form-control input" required v-model="data.field">
            <div class="form-text text-danger" v-if="error.field">{{ FA_LAN.PROFILE.ERROR_FIELD }}</div>
          </div>
          <div class="mb-4">
            <label class="form-label purple-text mb-0">{{ FA_LAN.PROFILE.JOB }}</label>
            <input type="text" class="form-control input" required v-model="data.job">
            <div class="form-text text-danger" v-if="error.job">{{ FA_LAN.PROFILE.ERROR_JOB }}</div>
          </div>
          <div class="mb-4">
            <label class="form-label purple-text mb-1">{{ FA_LAN.PROFILE.BIRTHDAY }}</label>
            <date-picker class="form-control input" format="YYYY-MM-DD" display-format="jYYYY/jMM/jDD" simple
                         v-model="data.birthday"></date-picker>
            <div class="form-text text-danger" v-if="error.birthday">{{ FA_LAN.PROFILE.ERROR_BIRTHDAY }}</div>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-5 col-12">
        <button class="btn blue-background text-white col-md-8 col-12" @click.prevent="update" :disabled="loading">
          <span class="spinner-border spinner-border-sm text-white" role="status" v-if="loading"></span>
          {{ FA_LAN.PROFILE.UPDATE }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import persian from "@/fa-IR/fa-ir";
import {onMounted, reactive, ref} from "vue";
import DatePicker from 'vue3-persian-datetime-picker'
import {post} from "@/store/mainRoute";
import {sweetAlert} from "@/js/alert/sweetAlert";
import {useStore} from "vuex";

export default {
  name: "Profile",
  components: {PageHeader, DatePicker},
  setup() {
    const FA_LAN = persian;
    const loading = ref(false);
    const hasEmpty = ref(false);
    const store = useStore();
    const data = reactive({
      name: '',
      sex: '',
      birthday: '',
      job: '',
      education: '',
      field: '',
    })
    const error = reactive({
      name: false,
      sex: false,
      birthday: false,
      job: false,
      education: false,
      field: false,
    })

    onMounted(async () => {
      await getProfile();
    })

    function checkInput() {
      error.name = data.name.length <= 3;
      error.sex = data.sex.length <= 3;
      error.birthday = data.birthday.length <= 3;
      error.job = data.job.length <= 3;
      error.education = data.education.length <= 3;
      error.field = data.field.length <= 3;

      for (let key in error) {
        if (error[key]) {
          hasEmpty.value = true;
        }
      }
    }

    async function getProfile() {
      loading.value = true

      await post('v1/user/getProfile', null)
          .then(function (response) {
            if (response.data.code === 200) {
              if (response.data.data.user_profile) {
                for (let key in data) {
                  data[key] = response.data.data.user_profile[key]
                }
              }
            }
          })

      loading.value = false;
    }

    async function update() {
      hasEmpty.value = false;

      checkInput();

      if (hasEmpty.value) {
        return;
      }

      loading.value = true

      await post('v1/user/updateProfile', data)
          .then(function (response) {
            if (response.data.code === 200) {
              sweetAlert('success', response.data.description)
              store.dispatch('wallet/fetch');

            } else {
              sweetAlert(error, response.data.description)

            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      loading.value = false;
    }

    return {FA_LAN, loading, data, error, update}
  }
}
</script>

<style>
.vpd-input-group input {
  border: none;
}
</style>