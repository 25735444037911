<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.HEADER.BUY +' '+ packageDetail.title"/>
    </div>
    <div class="container all-card-size">
      <div class=" mt-4">
        <div v-html="packageDetail.description" class="p-small"></div>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-5 col-12">
        <router-link class="btn blue-light-background col-md-8 col-12" :to="{name:'plans',params:{code:packageCode}}">
          {{ FA_LAN.BUTTON.BUY }}
        </router-link>
      </div>
      <SummaryReview :code="packageCode"/>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import persian from "../fa-IR/fa-ir";
import PageHeader from "../components/PageHeader";
import SummaryReview from "@/components/SummaryReview";

export default {
  name: "PackageInfo",
  components: {PageHeader, SummaryReview},
  setup() {
    const route = useRoute();
    const store = useStore();
    const FA_LAN = persian;
    const packageCode = route.params.code
    const packageDetail = store.getters["packages/package"](packageCode);

    return {packageDetail, FA_LAN, packageCode};
  }
}
</script>

<style scoped>

</style>