<template>
  <div class="mb-4 pb-4">
    <div v-for="category in categories" :key="category.id"
         class="mb-2 pt-2 pe-0 ps-0 bg-white shadow-sm rounded">
      <div class="d-flex">
        <div class="col-4 col-md-3 d-flex ps-3 pe-3 justify-content-center align-content-center border-secondary border-end">
          <img :src="category.logo" class="col-12 col-md-10">
        </div>
        <div class="col-8 col-md-9 ps-4">
          <p class="mb-2 pt-2">{{ category.title }}</p>
          <div><small class="secondary_text">{{ FA_LAN.MAIN.LESSON_COUNT }}{{ category.count }}</small></div>
          <div><small class="secondary_text">{{ FA_LAN.MAIN.UPDATE_DATE }}{{ toJalali(category.last) }}</small></div>
        </div>
      </div>
      <div class="d-flex p-0 ms-1 mt-1">
        <div class="col-4 col-md-3 text-center">
          <div class="col-11 buy-count-style small">
            <small> <i class="bi bi-person-check-fill me-1"></i>
              {{ withComma(category.buy_count) }}{{ FA_LAN.MAIN.STUDENT }}</small>
          </div>
        </div>
        <div class="col-8 col-md-9 text-end pe-0">
          <router-link v-if="category.count" class="btn btn-sm purple-background "
                       :to="{name:'educationList', params:{code:category.code,type:'categories'}}">
            <small> {{ FA_LAN.MAIN.SHOW_EDUCATION }}</small>
          </router-link>
          <button v-else class="btn btn-sm purple-background">{{ FA_LAN.MAIN.SOON }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import persian from "../../fa-IR/fa-ir";
import {gregorian_to_jalali} from "../../js/date/convert";
import {numberWithCommas} from "@/js/number";

export default {
  name: "Categories",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const categories = computed(() => store.getters["categories/all"]);

    function toJalali(date) {
      if (date) {
        const jalali = gregorian_to_jalali(date);
        const explodedDate = jalali.split(' ');
        return explodedDate[0];
      }
    }

    function withComma(number) {
      if (number === null || number === undefined) {
        return 0
      }
      return numberWithCommas(number)
    }

    return {categories, FA_LAN, toJalali, withComma}
  }
}
</script>

<style scoped>
img {
  max-width: 80% !important;
  width: 80% !important;
  height: 85% !important;
  max-height: 85% !important;
}
</style>