<template>
  <div class="container mb-4 pb-4">
    <router-link v-if="profileShow"
                 class="mt-2 mb-3 pt-2 pb-2 ps-3 pe-3 text-decoration-none text-body d-flex justify-content-between
             warning-box-style shadow-sm align-items-center"
                 :to="{name:'profile'}">
      {{ FA_LAN.MORE.PROFILE_COMPLETE }}
      <img class="icon-warning" src="../../assets/icons/info-red.png">
    </router-link>
    <div class="row">
      <div class="col-6 pe-1 ps-0">
        <div class="mb-2 pt-2 pb-1 pe-0 ps-0 bg-white shadow-sm rounded">
          <router-link :to="{name:'wallet'}" class="d-flex text-decoration-none align-items-center">
            <div class="col-9 text-center">
              <p class="m-0">{{ FA_LAN.MORE.WALLET }}</p>
            </div>
            <div class="col-3 text-center p-1">
              <img class="icon" src="../../assets/icons/sack-dollar.png">
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-6 pe-0 ps-1">
        <div class="mb-2 pt-2 pb-1 pe-0 ps-0 bg-white shadow-sm rounded">
          <div class="d-flex text-decoration-none align-items-center" @click="onlyAndroid">
            <div class="col-3 text-center p-1">
              <img class="icon" src="../../assets/icons/users-light.png">
            </div>
            <div class="col-9 text-center">
              <p class="m-0">{{ FA_LAN.MORE.FRIENDS_INVITATION }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2 pt-2 pb-1 pe-0 ps-0 row bg-white shadow-sm rounded">
      <router-link :to="{name:'groupPlan'}" class="row text-decoration-none align-items-center">
        <div class="col-10">
          <p class="mb-1 pt-2">{{ getGroupPlanTitle() }}</p>
          <p class="text-muted"><small>{{ showOffText() }}</small></p>
        </div>
        <div class="col-2 text-center p-1">
          <img class="icon" src="../../assets/icons/bullseye-arrow.png">
        </div>
      </router-link>
    </div>
    <div class="row">
      <div class="col-6 pe-1 ps-0">
        <div class="mb-2 pt-2 pb-1 pe-0 ps-0 bg-white shadow-sm rounded">
          <router-link :to="{name:'myEducation'}" class="d-flex text-decoration-none align-items-center">
            <div class="col-3 text-center p-1">
              <img class="icon" src="../../assets/icons/chart-line.png">
            </div>
            <div class="col-9 text-center">
              <p class="m-0">{{ FA_LAN.MORE.MY_EDUCATION }}</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-6 pe-0 ps-1">
        <div class="mb-2 pt-2 pb-1 pe-0 ps-0 bg-white shadow-sm rounded">
          <router-link :to="{name:'offList'}" class="d-flex text-decoration-none align-items-center">
            <div class="col-9 text-center">
              <p class="m-0">{{ FA_LAN.MORE.OFFS }}</p>
            </div>
            <div class="col-3 text-center p-1">
              <img class="icon" src="../../assets/icons/percent.png">
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="mb-2 pt-2 pb-1 pe-0 ps-0 row bg-white shadow-sm rounded">
      <a href="https://btzn.ir/office-web" target="_blank" class="row text-decoration-none align-items-center">
        <div class="col-2 text-center p-1">
          <img class="icon" src="../../assets/icons/chat-forum.png">
        </div>
        <div class="col-10">
          <div class="d-flex mb-1 pt-2">
            <p class="m-0 me-2">{{ FA_LAN.MORE.FORUM_TITLE }}</p>
            <p class="m-0 text-danger">{{FA_LAN.MORE.NEW}}</p>
          </div>
          <p class="text-muted"><small>{{ FA_LAN.MORE.FORUM_DESCRIPTION }}</small></p>
        </div>
      </a>
    </div>
    <div class="row">
      <div class="col-6 pe-1 ps-0">
        <div class="mb-2 pt-2 pb-1 pe-0 ps-0 bg-white shadow-sm rounded">
          <router-link :to="{name:'aboutUs'}" class="d-flex text-decoration-none align-items-center">
            <div class="col-9 text-center">
              <p class="m-0">{{ FA_LAN.MORE.ABOUT_US }}</p>
            </div>
            <div class="col-3 text-center p-1">
              <img class="icon" src="../../assets/icons/info.png">
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-6 pe-0 ps-1">
        <div class="mb-2 pt-2 pb-1 pe-0 ps-0 bg-white shadow-sm rounded">
          <router-link class="d-flex text-decoration-none align-items-center" :to="{name:'appSetup'}">
            <div class="col-3 text-center p-1">
              <img class="icon" src="../../assets/icons/download.png">
            </div>
            <div class="col-9">
              <p class="m-0 text-center">{{ FA_LAN.MORE.APP_SETUP }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="mb-2 pt-2 pb-1 pe-0 ps-0 row bg-white shadow-sm rounded">
      <router-link v-if="!apiKey" :to="{name:'login'}" class="row text-decoration-none align-items-center">
        <div class="col-10">
          <p class="mb-1 pt-2">{{ FA_LAN.MORE.PROFILE }}</p>
          <p class="text-muted"><small>{{ FA_LAN.MORE.LOGIN_DESCRIPTION }}</small></p>
        </div>
        <div class="col-2 text-center p-1">
          <img class="icon" src="../../assets/icons/user-alt.png">
        </div>
      </router-link>
      <div v-else class="row align-items-center" @click="toProfile">
        <div class="col-10">
          <div class="d-flex">
            <p class="mb-1 pt-2 me-2">{{ FA_LAN.MORE.PROFILE }}</p>
            <button class="btn btn-link text-danger text-decoration-none" @click.stop="logOut">
              {{ FA_LAN.MORE.EXIT }}
            </button>
          </div>
          <p class="text-muted"><small>{{ getLogedDescription() }}</small></p>
        </div>
        <div class="col-2 text-center p-1">
          <img class="icon" src="../../assets/icons/user-alt.png">
        </div>
      </div>
    </div>
    <div class="mb-2 pt-2 pb-1 pe-0 ps-0 row bg-white shadow-sm rounded">
      <router-link :to="{name:'negahProduct'}" class="row text-decoration-none align-items-center">
        <div class="col-2 text-center p-1">
          <img class="icon" src="../../assets/icons/negah-logo.png">
        </div>
        <div class="col-10">
          <p class="mb-1 pt-2">{{ FA_LAN.MORE.NEGAH_TITLE }}</p>
          <p class="text-muted"><small>{{ FA_LAN.MORE.NEGAH_DESCRIPTION }}</small></p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {sweetAlert} from "@/js/alert/sweetAlert";
import {useRouter} from "vue-router";
import {post} from "@/store/mainRoute";

export default {
  name: "MoreOptions",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const groupPlan = computed(() => store.getters["groupPlan/get"]);
    const apiKey = computed(() => store.getters["apiKey/get"])
    const phone = computed(() => store.getters["apiKey/getPhone"])
    const router = useRouter();
    const profileShow = ref(false);

    onMounted(() => {
      getProfile();
    })

    function showOffText() {
      if (groupPlan.value.is_active) {
        return FA_LAN.MORE.GROUP_OFF_REMAIN.replace("{day}", groupPlan.value.remain_days)
      } else {
        return FA_LAN.MORE.GROUP_OFF_FINISHED
      }
    }

    function getGroupPlanTitle() {
      return FA_LAN.MORE.GROUP_OFF_TITLE.replace("{percentage}", groupPlan.value.off_percentage)
    }

    function logOut() {
      store.commit('apiKey/remove');
    }

    function getLogedDescription() {
      return FA_LAN.MORE.LOGED_DESCRIPTION.replace("{phone}", phone.value)
    }

    function onlyAndroid() {
      sweetAlert('warning', FA_LAN.MORE.ONLY_IN_ANDROID)
    }

    function comingSoon() {
      sweetAlert('warning', FA_LAN.MORE.COMING_SOON)
    }

    function toProfile() {
      router.push({name: 'profile'})
    }

    async function getProfile() {
      await post('v1/user/getProfile')
          .then(function (response) {
            if (response.data.code === 200) {
              profileShow.value = !response.data.data.user_profile;
            }
          })
    }

    return {
      FA_LAN,
      apiKey,
      profileShow,
      showOffText,
      getGroupPlanTitle,
      getLogedDescription,
      logOut,
      onlyAndroid,
      comingSoon,
      toProfile
    }
  }
}
</script>

<style scoped>
.icon {
  width: 50px;
  height: auto;
}

.icon-warning {
  width: 30px;
  height: auto;
}

a, a:hover {
  color: #333;
}

.bg-disable {
  background-color: #f9f9f9;
}
</style>