<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       :id="'payChoose'+planId">
    <div class="justify-content-center pt-3 pb-3">
      <div class="all-card-size mx-auto">
        <h5 class="offcanvas-title text-center purple-text mt-2" id="offcanvasBottomLabel">
          {{ FA_LAN.PAY.CHOOSE_TITLE }}</h5>
        <hr class="text-center">
        <p>{{ FA_LAN.PAY.CHOOSE_DESCRIPTION }}</p>
        <hr class="text-center">
        <router-link type="button" class="btn btn-link btn-sm purple-text text-decoration-none text-start p-0 m-0"
                     :to="{name:'payByIpg',params:{'planId':planId,'type':type}}">
          {{ FA_LAN.PAY.IPG }}
        </router-link>
        <hr class="text-center">
        <router-link type="button" class="btn btn-link btn-sm purple-text text-decoration-none text-start p-0 m-0"
                     :to="{name:'payByCrypto',params:{'planId':planId,'type':type}}">
          {{ FA_LAN.PAY.CRYPTO }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";

export default {
  name: "PayChoose",
  props: ['planId', 'type'],
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>