<template>
  <div class="container all-card-size bg-light pt-3 pb-2">
    <input type="text" class="form-control sticky-top" v-model="search" :placeholder="FA_LAN.FORM.SEARCH">
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {ref, watch} from "vue";

export default {
  name: "Search",
  setup(props, context) {
    const FA_LAN = persian;
    const search = ref('');
    const awaitingSearch = ref(false);

    watch(search, () => {
      if (!awaitingSearch.value) {
        setTimeout(() => {

          props;
          context.emit('search', search.value);
          awaitingSearch.value = false;
        }, 2000); // 2 sec delay
      }
      awaitingSearch.value = true;
    })

    return {FA_LAN, search}
  }
}
</script>

<style scoped>

</style>