<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.MORE.GROUP_PLAN_PAGE_TITLE"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <div class="mb-2 pt-2 pb-1 pe-0 ps-0 bg-white shadow-sm rounded">
        <p class="blue-text text-center p-2 m-0">{{ getTitle() }}</p>
      </div>
      <div class="mb-2 p-2 bg-white shadow-sm rounded">
        <table class="table table-borderless table-sm">
          <thead>
          <tr>
            <td><strong>{{ FA_LAN.MORE.EDUCATION_TITLE }}</strong></td>
            <td class="text-center"><strong>{{ FA_LAN.MORE.EDUCATION_PRICE }}</strong></td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="plan in groupPlan.plans" :key="plan.id">
            <td>{{ plan.main_category_title }}<span class="text-muted"> - {{ plan.sub_category_title }}</span></td>
            <td class="text-center">{{ withComma(plan.amount) }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <hr>
            </td>
          </tr>
          <tr>
            <td><strong>{{ FA_LAN.MORE.GROUP_PLAN_SUM }}</strong></td>
            <td class="text-center"><strong>{{ withComma(groupPlan.sum_price) }}</strong></td>
          </tr>
          <tr class="orange-text">
            <td><strong>{{ getWithOff() }} ({{ FA_LAN.PLANS.TOMAN }})</strong></td>
            <td class="text-center"><strong>{{ withComma(groupPlan.amount) }}</strong></td>
          </tr>
          </tbody>
        </table>
      </div>
      <p class="text-center text-muted">{{ getOffRemain() }}</p>
      <div class="d-grid gap-2 mt-4">
        <button v-if="checkAvailable() && checkLogin()" class="btn blue-background text-white"
                data-bs-toggle="offcanvas"
                :data-bs-target="'#payChoose1'" aria-controls="offcanvasBottom">
          <small> {{ FA_LAN.MORE.GROUP_PLAN_BTN }}</small>
        </button>
        <router-link v-if="!checkLogin()" class="btn blue-background text-white" :to="{name:'login'}">
          <small> {{ FA_LAN.MORE.GROUP_PLAN_BTN }}</small>
        </router-link>
        <button v-if="!checkAvailable()" class="btn blue-background text-white" @click="notAvailableMessage()">
          <small> {{ FA_LAN.MORE.GROUP_PLAN_BTN }}</small>
        </button>
        <PayChoose plan-id="1" type="all"/>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import PageHeader from "@/components/PageHeader";
import {useStore} from "vuex";
import {computed} from "vue";
import {numberWithCommas} from "@/js/number";
import {sweetAlert} from "@/js/alert/sweetAlert";
import PayChoose from "@/components/convas/PayChoose";

export default {
  name: "GroupPlan",
  components: {PageHeader, PayChoose},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const groupPlan = computed(() => store.getters["groupPlan/get"]);

    function getTitle() {
      return FA_LAN.MORE.GROUP_OFF_PAGE_TITLE.replace("{percentage}", groupPlan.value.off_percentage);
    }

    function getWithOff() {
      return FA_LAN.MORE.GROUP_PLAN_WITH_OFF.replace("{percentage}", groupPlan.value.off_percentage);
    }

    function getOffRemain() {
      return FA_LAN.MORE.GROUP_OFF_REMAIN_BIG.replace("{day}", groupPlan.value.remain_days);
    }

    function withComma(number) {
      return numberWithCommas(number)
    }

    function checkAvailable() {
      return groupPlan.value.is_active;
    }

    function notAvailableMessage() {
      if (!groupPlan.value.is_active) {
        sweetAlert('error', FA_LAN.MORE.GROUP_OFF_FINISHED);
      }
    }

    function checkLogin() {
      return store.getters["apiKey/get"];
    }

    return {
      FA_LAN,
      groupPlan,
      getTitle,
      getWithOff,
      getOffRemain,
      withComma,
      checkAvailable,
      checkLogin,
      notAvailableMessage
    }
  }
}
</script>

<style scoped>

</style>