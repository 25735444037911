import {createRouter, createWebHistory, createWebHashHistory} from "vue-router";
import {showScroll} from "./js/protect/protectInspect";

import Home from "./pages/Home";
import EducationList from "./pages/EducationList";
import Login from "./pages/Login";
import PackageInfo from "./pages/PackageInfo";
import ShowVideo from "./pages/ShowVideo";
import Plans from "./pages/Plans";
import Verify from "./pages/Verify";
import AboutUs from "./pages/AboutUs";
import GroupPlan from "@/pages/GroupPlan";
import MyEducation from "@/pages/MyEducation";
import OffList from "@/pages/OffList";
import NegahProducts from "@/pages/NegahProducts";
import CryptoPay from "@/pages/CryptoPay";
import AppSetup from "@/pages/AppSetup";
import {isDesktop} from "@/swichDevice";
import Wallet from "@/pages/Wallet";
import IPGPay from "@/pages/IPGPay";
import WalletRule from "@/pages/WalletRule";
import Profile from "@/pages/Profile";
import ShowReview from "@/pages/ShowReview";

const routes = [
    {path: '/', name: "home", component: Home},
    {path: '/educations/:type/:code', name: "educationList", component: EducationList},
    {path: '/login', name: "login", component: Login},
    {path: '/packageInfo/:code', name: "packageInfo", component: PackageInfo},
    {path: '/showVideo/:code/:id/:hashedID/:mainCategory/:name?', name: "showVideo", component: ShowVideo},
    {path: '/plans/:code', name: "plans", component: Plans},
    {path: '/plans/groupOff', name: "groupPlan", component: GroupPlan},
    {path: '/plans/my', name: "myEducation", component: MyEducation},
    {path: '/verify/:market', name: "verify", component: Verify},
    {path: '/aboutUs', name: "aboutUs", component: AboutUs},
    {path: '/offList', name: "offList", component: OffList},
    {path: '/negahProduct', name: "negahProduct", component: NegahProducts},
    {path: '/payByCrypto/:type/:planId?', name: "payByCrypto", component: CryptoPay},
    {path: '/payByIpg/:type/:planId?', name: "payByIpg", component: IPGPay},
    {path: '/app', name: "appSetup", component: AppSetup},
    {path: '/wallet', name: "wallet", component: Wallet},
    {path: '/wallet/rule', name: "walletRule", component: WalletRule},
    {path: '/profile', name: "profile", component: Profile},
    {path: '/reviews/:code', name: "reviews", component: ShowReview},
]

const router = createRouter({
    history: isDesktop ? createWebHashHistory() : createWebHistory(),
    routes
})

router.beforeEach((to, form, next) => {
    if (to.name === 'showVideo') {
        // preventScroll();
        next();
    } else {
        showScroll();
        next();
    }
})

export default router;