<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.APP_SETUP.TITLE"/>
    </div>
    <div class="m-2">
      <div class="container p-2 mt-4 mb-5 bg-white all-card-size rounded shadow-sm">
        <div class="text-center">
          <p class="m-0 mb-1"><small class="text-muted">{{ FA_LAN.APP_SETUP.DESCRIPTION }}</small></p>
          <p class="m-0 mb-1"><small class="text-muted">{{ FA_LAN.APP_SETUP.WHAT_IS_QR }}</small></p>
        </div>
        <hr class="text-center">
        <div class="row text-center justify-content-center">
          <div class="col-md-5 col-9 m-3">
            <ShowApps
                url="https://play.google.com/store/apps/details?id=ir.negahgroup.office.education"
                image="get-google-play"
                :text="FA_LAN.APP_SETUP.ANDROID_GOOGLE"
            />
          </div>
          <div class="col-md-5 col-9 m-3">
            <ShowApps
                url="https://dl.aliyavary.ir/office_education/app-setup/office-education-4.6.0.apk"
                image="get-android-direct"
                :text="FA_LAN.APP_SETUP.ANDROID_DIRECT"
            />
          </div>
        </div>
        <div class="row text-center justify-content-center">
          <div class="col-md-5 col-9 m-3">
            <ShowApps
                url="https://office.negahgroup.ir"
                image="get-iphone"
                :text="FA_LAN.APP_SETUP.IPHONE"
            />
          </div>
          <div class="col-md-5 col-9 m-3">
            <ShowApps
                url="https://cafebazaar.ir/app/ir.negahgroup.office.education"
                image="get-cafe-bazzar"
                :text="FA_LAN.APP_SETUP.ANDROID_BAZAAR"
            />
          </div>
        </div>
        <div class="row text-center justify-content-center">
          <div class="col-md-5 col-9 m-3">
            <ShowApps
                url="https://office.negahgroup.ir"
                image="get-web"
                :text="FA_LAN.APP_SETUP.WEB"
            />
            <!--            <ShowApps-->
            <!--                url="https://dl.aliyavary.ir/office_education/app-setup/office-education-4.0.0.AppImage"-->
            <!--                image="get-linux"-->
            <!--                :text="FA_LAN.APP_SETUP.LINUX"-->
            <!--            />-->
          </div>
          <div class="col-md-5 col-9 m-3">
            <ShowApps
                url="https://dl.aliyavary.ir/office_education/app-setup/office-education-4.0.3.exe"
                image="get-windows"
                :text="FA_LAN.APP_SETUP.WINDOWS"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import PageHeader from "@/components/PageHeader";
import ShowApps from "@/components/ShowApps";

export default {
  name: "AppSetup",
  components: {ShowApps, PageHeader},
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>