import {post} from "../../store/mainRoute";
import {platForm} from "@/swichDevice";

export async function createPayLink(type, id) {
    return await post('v2/pay/createPayUrl', {
        id: id,
        type: type,
        market: platForm
    })
}

export async function verify(authority) {
    return await post('v2/pay/verify', {
        authority: authority,
    })
}

export async function cancel(authority) {
    return await post('v2/pay/cancel', {
        authority: authority,
    })
}

export async function getDetail(type, id) {
    return await post('v2/pay/getDetail', {
        id: id,
        type: type,
    })
}