<template>
  <div>
    <div class="sticky-top" v-if="showDesktopHeader">
      <PageHeader :title="educationName"/>
    </div>
    <div class="d-flex justify-content-center pt-2">
      <div class="col-md-9 ">
        <video playsinline controls controlsList="nodownload">
          <source :src="newUrl" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import {convertURL, decodeEducationID} from "../js/protect/encodeVideoId";
import {onMounted, ref} from 'vue';
import {useRoute} from "vue-router";
import {post} from "../store/mainRoute";
import PageHeader from "@/components/PageHeader";
import {isDesktop} from "@/swichDevice";

export default {
  name: "Home",
  components: {PageHeader},
  setup() {
    const newUrl = ref('');
    const route = useRoute();
    const code = route.params.code;
    const hashedID = route.params.hashedID;
    const educatinID = route.params.id;
    const mainCategory = route.params.mainCategory;
    const educationName = route.params.name ? route.params.name : "";
    const showDesktopHeader = isDesktop;

    onMounted(() => {
      convertUrl();
      saveSeen();
    })

    function convertUrl() {
      newUrl.value = convertURL(code, hashedID);
    }

    function saveSeen() {
      setTimeout(function () {
        post('v1/saveSeen', {
          code: mainCategory,
          education_id: decodeEducationID(educatinID)
        })
      }, 25000);
    }

    return {newUrl, showDesktopHeader, educationName}
  }

}
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
}
</style>
