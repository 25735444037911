<template>
  <footer class="footer mt-auto fixed-bottom text-center">
    <div class="container py-1 pt-2 bg-light all-card-size">
      <router-link class="text-decoration-none" :to="{name: 'reviews', params: {code: code}}">
        <Loading v-if="loading" :loading="loading"/>
        <div v-else class="d-flex justify-content-center mb-1">
          <div>
            <h6 class="m-0 text-body">{{ rate }}<i class="bi bi-star-fill align-middle orange-text ms-2 fs-5"></i>
            </h6>
            <p class="m-0 text-muted small">{{ getRateCountText() }}</p>
          </div>
          <div class="ms-3 me-4 pt-3">
            <span class="ps-2 pe-2 ">{{ FA_LAN.BUTTON.BTN_SEPERATOR }}</span>
          </div>
          <div>
            <h6 class="m-0 text-body">{{ reviewCount }}
              <i class="bi bi-chat-square-text-fill align-middle purple-text ms-2 fs-5"></i>
            </h6>
            <p class="m-0 text-muted small">{{ FA_LAN.REVIEW.SEE_ALL_REVIEW }}</p>
          </div>
        </div>
      </router-link>
    </div>
  </footer>
</template>

<script>
import {post} from "@/store/mainRoute";
import {onMounted, ref} from "vue";
import Loading from "@/components/Loading";
import persian from "@/fa-IR/fa-ir";

export default {
  name: "SummaryReview",
  components: {Loading},
  props: ['code'],
  setup(props) {
    const FA_LAN = persian;
    const loading = ref(false);
    const rate = ref(0);
    const reviewCount = ref(0);
    const rateCount = ref(0);

    onMounted(async () => {
      await getSummary();
    })

    async function getSummary() {
      loading.value = true;

      await post(`v1/review/getSummary`, {
        main_category: props.code,
      }).then(function (response) {
        if (response.data.code === 200) {
          loading.value = false;

          rate.value = response.data.data.rate;
          rateCount.value = response.data.data.rate_count;
          reviewCount.value = response.data.data.review_count;
        }
      })
    }

    function getRateCountText() {
      return FA_LAN.REVIEW.RATE_COUNT.replace('{score}', rateCount.value)
    }


    return {FA_LAN, loading, rate, rateCount, reviewCount, getRateCountText}
  }

}
</script>

<style scoped>

</style>