<template>
  <a :href="url" target="_blank">
    <img :src="getImgUrl()" />
  </a>
  <div>
    <button class="btn btn-link btn-sm text-decoration-none text-muted mt-1" data-bs-toggle="offcanvas"
            :data-bs-target="'#'+image" aria-controls="offcanvasBottom">
      {{ FA_LAN.APP_SETUP.QR_CODE }}
    </button>
    <QR :id="image" :text="text" :url="url"/>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import QR from "@/components/convas/QR";

export default {
  name: "ShowApps",
  components: {QR},
  props: ['url', 'image', 'text'],
  setup(props) {
    const FA_LAN = persian;

   function getImgUrl() {
      const images = require.context('../assets/app/', false, /\.png$/)
      return images('./' + props.image + ".png")
    }

    return {FA_LAN,getImgUrl}
  }
}
</script>

<style scoped>

</style>