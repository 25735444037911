<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.OFF.PAGE_TITLE"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <p v-if="empty" class="mt-3">{{ FA_LAN.BODY.EMPTY }}</p>
      <div v-else>
        <div v-if="hasActive" class="mt-2 row bg-white shadow-sm rounded blue-text text-center">
          <p class="m-0 pe-2 pe-2 pt-3 pb-3">{{ FA_LAN.OFF.ACTIVE_OFF_APPLIED }}</p>
        </div>
        <div v-for="item in offList" :key="item.id" class="mt-2 bg-white shadow-sm rounded">
          <div class="d-flex">
            <div class="col-8"><img :src="item.image"></div>
            <div class="col-4 text-center justify-content-center p-2">
              <div class="mt-3 off-status-style p-md-2 p-1" :class="getStatusStyle(item.status)">
                <small> {{ getStatusText(item.status) }}</small>
              </div>
              <div class="mt-md-3 mt-1 off-type-style p-md-2 p-1"><small>{{ getTypeText(item.type) }}</small></div>
              <div class="mt-md-4 mt-2 off-condition-style purple-text">
                <h6><span>{{ FA_LAN.OFF.PERCENTAGE_SIGN }}</span>{{ item.amount }}</h6>
              </div>
            </div>
          </div>
          <div><p class="m-0 mt-3 ps-2"> {{ item.title }}</p></div>
          <div class="ps-2 pe-2"><small class="secondary_text"> {{ item.description }}</small></div>
          <hr class="col-11">
          <div class="d-flex justify-content-around text-muted p-2 pt-0">
            <div>
              <small><span>{{ FA_LAN.OFF.CREATE_DATE }}</span>{{ toJalali(item.created_at) }}</small>
            </div>
            <div>
              <small><span>{{ FA_LAN.OFF.VALID_DATE_SIMPLY }}</span>{{ toJalali(item.valid_date) }}</small>
            </div>
            <div>
              <small><span>{{ FA_LAN.OFF.COUNT_SIMPLY }}</span>{{ item.limit_count }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :loading="loading"/>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Loading from "@/components/Loading";
import persian from "@/fa-IR/fa-ir";
import {post} from "@/store/mainRoute";
import {gregorian_to_jalali} from "@/js/date/convert";
import {onMounted, ref} from "vue";

export default {
  name: "OffList",
  components: {PageHeader, Loading},
  setup() {
    const FA_LAN = persian;
    const offList = ref(null)
    const hasActive = ref(false)
    const empty = ref(false);
    const loading = ref(false);

    onMounted(async () => {
      await getOffList();
    })

    async function getOffList() {
      loading.value = true;

      await post('v1/getUserOffList', null)
          .then(function (response) {
            if (response.data.code === 200) {
              offList.value = response.data.data.off_lists
              hasActive.value = response.data.data.has_active_off

              checKEmptyObject();
            }
          })

      loading.value = false;
    }

    function toJalali(date) {
      if (date) {
        let dateArray = date.split(" ");//only date is important
        return gregorian_to_jalali(dateArray[0]);
      }
    }

    function checKEmptyObject() {
      empty.value = offList.value.length === 0;
    }

    function getTypeText(type) {
      return type == 'public' ? FA_LAN.OFF.PUBLIC : FA_LAN.OFF.PRIVATE;
    }

    function getStatusText(status) {
      let statusText;
      switch (status) {
        case "used":
          statusText = FA_LAN.OFF.USED;
          break;
        case "expired":
          statusText = FA_LAN.OFF.EXPIRED;
          break;
        case "active":
          statusText = FA_LAN.OFF.ACTIVE;
          break;
      }

      return statusText;
    }

    function getStatusStyle(status) {
      let textColor;
      switch (status) {
        case "used":
          textColor = 'purple-text';
          break;
        case "expired":
          textColor = 'orange-text';
          break;
        case "active":
          textColor = 'blue-text';
          break;
      }

      return textColor;
    }

    return {FA_LAN, offList, empty, hasActive, loading, toJalali, getTypeText, getStatusText, getStatusStyle}
  }
}
</script>

<style scoped>

</style>