<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.NEGAH.PAGE_TITLE"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <p v-if="empty" class="mt-3">{{ FA_LAN.BODY.EMPTY }}</p>
      <div v-else>
        <div v-for="item in products" :key="item.id" class="mt-2 d-flex bg-white shadow-sm rounded">
          <div class="col-4 d-flex align-items-center"><img class="col-md-8 ps-1 pe-1" :src="item.image_square"></div>
          <div class="row col-8 p-0 m-0 justify-content-center">
            <div class="p-2">
              <div><p class="m-0 mt-1">{{ item.name }}</p></div>
              <div><small class="secondary_text">{{ item.description }}</small></div>
            </div>
            <div class="d-flex justify-content-end align-self-end pe-0">
              <a class="btn btn-sm purple-background text-decoration-none" :href="item.url"
                 target="_blank">{{ FA_LAN.NEGAH.MORE }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :loading="loading"/>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import PageHeader from "@/components/PageHeader";
import persian from "@/fa-IR/fa-ir";
import {onMounted, ref} from "vue";
import {negahProduct} from "@/store/mainRoute";

export default {
  name: "NegahProducts",
  components: {PageHeader, Loading},
  setup() {
    const FA_LAN = persian;
    const loading = ref(false)
    const products = ref(null);
    const empty = ref(false);

    onMounted(async () => {
      await getNegahProductList();
    })

    async function getNegahProductList() {
      loading.value = true;

      await negahProduct()
          .then(function (response) {
            if (response.data.code === 200) {
              products.value = response.data.products

              products.value = products.value.filter(item => item.code !== 'excel');
              checKEmptyObject();
            }
          })

      loading.value = false;
    }

    function checKEmptyObject() {
      empty.value = products.value.length === 0;
    }

    return {FA_LAN, products, loading, empty}
  }
}
</script>

<style scoped>

</style>