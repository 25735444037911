const pay ={
    PENDING:"درحال اتصال به درگاه...",
    RESULT:"نتیجه پرداخت",
    RESULT_TITLE_SUCCESS:"پرداخت موفق",
    RESULT_TITLE_WRONG:"پرداخت ناموفق",
    RESULT_CODE:"کد خطا: ",
    DESCRIPTION:"توضیحات: ",
    AMOUNT:"مبلغ: ",
    REF_ID:"کد پیگیری: ",

    //pay choose off convase
    CHOOSE_TITLE:"انتخاب نحوه پرداخت",
    CHOOSE_DESCRIPTION:"پرداخت با ارزهای دیجیتال برای کاربران خارج از ایران یا آشنا با رمز ارزها می‌باشد",
    IPG:"درگاه بانک - کلیه کارت‌های بانکی عضو شتاب",
    CRYPTO:"ارزهای دیجیتال (رمز ارزها)"
}

export default pay