<template>
  <footer v-if="!isReview() && !isVideo() " class="footer mt-auto fixed-bottom text-center">
    <div class="container py-1 bg-light all-card-size">
      <SummaryReport v-if="isHome()"/>
      <div v-else-if="showWebFooter">
        <router-link class="btn btn-link btn-sm text-muted" :to="{name:'login'}">
          {{ FA_LAN.MORE.PROFILE }}
        </router-link>
        <span class="ps-2 pe-2">{{ FA_LAN.BUTTON.BTN_SEPERATOR }}</span>
        <router-link class="btn btn-link btn-sm text-muted" :to="{name:'appSetup'}">
          {{ FA_LAN.MORE.APP_SETUP }}
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import SummaryReport from "@/components/SummaryReport";
import {useRoute} from "vue-router";
import {computed} from "vue";
import {isDesktop} from "@/swichDevice";

export default {
  name: "Footer",
  components: {SummaryReport},
  setup() {
    const FA_LAN = persian;
    const route = useRoute();
    const thisRout = computed(() => route.name);
    const showWebFooter = !isDesktop;

    function isVideo() {
      return thisRout.value === 'showVideo';
    }

    function isReview() {
      return thisRout.value === 'educationList' || thisRout.value === 'packageInfo' || thisRout.value === 'reviews';
    }

    function isHome() {
      return thisRout.value === 'home';
    }

    return {FA_LAN, showWebFooter, isVideo, isReview, isHome}
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>