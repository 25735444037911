import {createStore} from "vuex";
import {packages} from "./modules/packages";
import {categories} from "./modules/categories";
import {apiKey} from "./modules/apiKey";
import {summaryReports} from "@/store/modules/summaryReports";
import {groupPlan} from "@/store/modules/groupPlan";
import {wallet} from "@/store/modules/wallet";

const store = createStore({
    modules: {packages, categories, apiKey, summaryReports,groupPlan,wallet}
})

export default store