import body from "./includes/body";
import main from "./includes/main";
import buttons from "./includes/buttons";
import forms from "./includes/forms";
import headers from "./includes/headers";
import educations from "./includes/educations";
import offconvase from "./includes/offconvase";
import plans from "./includes/plans";
import pay from "./includes/pay";
import aboutUs from "./includes/aboutUs";
import off from "./includes/off";
import pwa from "./includes/pwa";
import moreOptions from "@/fa-IR/includes/moreOptions";
import negah from "@/fa-IR/includes/negah";
import cryptoPay from "@/fa-IR/includes/cryptoPay";
import appSetup from "@/fa-IR/includes/appSetup";
import wallet from "@/fa-IR/includes/wallet";
import ipgPay from "@/fa-IR/includes/ipgPay";
import profile from "@/fa-IR/includes/profile";
import review from "@/fa-IR/includes/review";

const persian = {
    BODY: body,
    MAIN: main,
    BUTTON: buttons,
    FORM: forms,
    HEADER: headers,
    EDUCATION: educations,
    OFFCANVAS: offconvase,
    PLANS: plans,
    PAY: pay,
    ABOUT_US: aboutUs,
    OFF: off,
    PWA: pwa,
    MORE: moreOptions,
    NEGAH: negah,
    CRYPTO: cryptoPay,
    APP_SETUP: appSetup,
    WALLET: wallet,
    IPG_PAY: ipgPay,
    PROFILE: profile,
    REVIEW: review,
}

// const persian = Object.assign(main, forms, buttons, tables)

export default persian;