const moreOptions = {
    GROUP_OFF_TITLE: "{percentage} درصد تخفیف برای تمام آموزشها",
    GROUP_OFF_FINISHED: "مهلت استفاده از تخفیف ویژه به پایان رسید",
    GROUP_OFF_REMAIN: "فقط {day} روز دیگر فرصت باقیست",
    GROUP_OFF_PAGE_TITLE: "تمامی آموزش ها را با {percentage} درصد تخفیف فعالسازی نمایید",
    EDUCATION_TITLE: "عنوان آموزش",
    EDUCATION_PRICE: "قیمت اصلی",
    GROUP_PLAN_SUM: "جمع کل (بدون تخفیف)",
    GROUP_PLAN_WITH_OFF: "با {percentage} درصد تخفیف",
    GROUP_OFF_REMAIN_BIG: "برای استفاده از این تخفیف فقط {day} روز دیگر فرصت باقیست",
    GROUP_PLAN_BTN: "فعالسازی تمام آموزشها با تخفیف",
    GROUP_PLAN_PAGE_TITLE: "تخفیف ویژه",
    MY_EDUCATION: 'دوره‌های من',
    OFFS:"تخفیف‌ها",
    NEWS_TITLE:"اخبار و اطلاعیه‌ها",
    NEWS_DESCRIPTION:"اطلاع از آموزش‌های جدید، طرح‌ها و جشنواره‌ها",
    FORUM_TITLE:"پرسش و پاسخ رایگان",
    FORUM_DESCRIPTION:"پاسخ به سؤالات شما، انجام پروژه‌ها و اعتبار رایگان",
    NEW:"جدید",
    ABOUT_US:"درباره ما",
    PROFILE:"حساب کاربری",
    EXIT:"خروج",
    APP_SETUP:"نصب برنامه",
    LOGIN_DESCRIPTION:"برای استفاده از تمامی خدمات یا بازیابی خریدها ورود کنید",
    LOGED_DESCRIPTION:"شما با شماره {phone} وارد شدید",
    NEGAH_TITLE:"سایر محصولات و خدمات ما",
    NEGAH_DESCRIPTION:"کلی نرم افزار و برنامه کاربردی باحال داریم...",
    WALLET:"مشاهده اعتبار",
    FRIENDS_INVITATION:"دعوت از دوستان",
    ONLY_IN_ANDROID:"این قابلیت فقط در نسخه اندروید وجود دارد",
    COMING_SOON:"به زودی این قسمت فعال خواهد شد",
    PROFILE_COMPLETE:"با تکمیل پروفایل خود، اعتبار رایگان هدیه بگیرید",
}

export default moreOptions;