<template>
  <div class="blue-background text-white p-2">
    <div class="container d-flex align-content-center align-items-center all-card-size">
      <button class="btn bnt-link text-decoration-none text-white m-0 p-0" @click="back">
        <small class="m-0"><i class="bi bi-arrow-right pe-2"></i></small>
      </button>
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {useRouter} from "vue-router";

export default {
  name: "PageHeader",
  props: ['title'],
  setup() {
    const FA_LAN = persian;
    const router = useRouter();

    function back() {
      router.back();
    }

    return {FA_LAN, back}
  }
}
</script>

<style scoped>

</style>