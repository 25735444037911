<template>
  <div v-if="off" class="offcanvas show offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="off">
    <div class="justify-content-center pt-3 pb-3">
      <div class="all-card-size mx-auto">
        <img class="col-md-8 mx-auto p-0" :src="off.image">
        <h5 class="offcanvas-title text-center purple-text mt-2" id="offcanvasBottomLabel">
          {{ off.title }}</h5>
        <hr class="text-center">
        <p>{{ off.description }}</p>
        <hr class="text-center">
        <p class="m-1">{{ FA_LAN.OFF.AMOUNT }}{{ off.amount }}
          <span v-if="off.percentage">{{ FA_LAN.OFF.PERCENTAGE }}</span>
          <span v-else>{{ FA_LAN.PLANS.TOMAN }}</span>
        </p>
        <p class="m-1">{{ FA_LAN.OFF.COUNT }}{{ off.limit_count }}</p>
        <p class="m-1">{{ FA_LAN.OFF.VALID_DATE }}{{ toJalali(off.valid_date) }}</p>
        <hr class="text-center">
        <small class="mb-2 text-center light-purple-text ">{{ FA_LAN.OFF.AUTO_ASSIGN }}</small>
        <div class="d-grid gap-2 col-md-8 mx-auto">
          <button type="button" class="btn btn-sm blue-background text-white" data-bs-dismiss="offcanvas" @click="hide">
            {{ FA_LAN.OFF.SEEN }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {gregorian_to_jalali} from "../../js/date/convert";
import {onMounted} from "vue";

export default {
  name: "Off",
  props: ['off', "source"],
  setup(props) {
    const FA_LAN = persian;

    onMounted(() => {
      if (props.off) {
        const app = document.getElementById("off");
        app.style.visibility = "visible";
        app.setAttribute("role", "dialog");
        app.setAttribute("aria-modal", "true")
      }
    })

    function toJalali(date) {
      if (date) {
        const jalali = gregorian_to_jalali(date);
        const explodedDate = jalali.split(' ');
        return explodedDate[0];
      }
    }

    function hide() {
      if (props.source !== 0) {
        const app = document.getElementById("off");
        app.style.visibility = "hidden";
      }
    }

    return {FA_LAN, toJalali, hide}
  }
}
</script>

<style scoped>

</style>