const buttons = {
    BUY:"خرید",
    SEE_EDUCATION:"مشاهده آموزش",
    TO_HOME:"بازگشت به صفحه اصلی",
    TO_APP:"بازگشت به اپلیکیشن آموزش آفیس",
    TO_DESKTOP:"بستن و بازگشت به اپلیکیشن آموزش آفیس",
    ANDROID:"اپلیکیشن اندروید",
    BTN_SEPERATOR:" | ",
    EXTRA_URL:"دانلود فایل کمکی",
}

export default buttons;