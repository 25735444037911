<template>
  <div>
    <div class="sticky-top">
      <div class="navbar blue-background text-white pt-2">
        <div class="container all-card-size d-flex justify-content-between">
          <div class="pt-2 pb-2">
            <h4> {{ FA_LAN.MAIN.TITLE }}</h4>
            <p class="m-0">{{ FA_LAN.MAIN.ADVANTAGES }}</p>
          </div>
          <div>
            <router-link class="buy-count-style text-decoration-none ps-2 pe-2 pt-1 pb-1" :to="{name:'wallet'}">
              <small>{{ FA_LAN.WALLET.CREDIT }}{{ withComma(walletAmount) }}{{ FA_LAN.PLANS.TOMAN }}</small>
            </router-link>
          </div>
        </div>
      </div>
      <div class="container pe-0 ps-0 pb-2 bg-light all-card-size">
        <ul class="nav nav-fill">
          <button class="nav-item blue-text p-2 bg-white border-0" :class="{'tab-active':category}"
                  @click="showCategories">
            {{ FA_LAN.MAIN.MAIN_CATEGORY_TAB }}
          </button>
          <button class="nav-item blue-text p-2 bg-white border-0" :class="{'tab-active':packages}"
                  @click="showPackages">
            {{ FA_LAN.MAIN.PACKAGES_TAB }}
          </button>
          <button class="nav-item blue-text p-2 bg-white border-0" :class="{'tab-active':moreOptions}"
                  @click="showMore">
            {{ FA_LAN.MAIN.MORE_TAB }}
            <span class="badge bg-danger border border-light rounded-circle p-1 translate-middle ms-2">
              <span class="visually-hidden">new</span>
            </span>
          </button>
        </ul>
      </div>
    </div>
    <div class="container all-card-size">
      <div class="card pb-5 border-0 bg-light">
        <div class="card-body p-0">
          <div class="pt-1">
            <Loading :loading="loading" v-if="loading"/>
            <div v-else>
              <transition name="slide-fade">
                <Categories v-if="category"/>
                <Packages v-else-if="packages"/>
                <MoreOptions v-else-if="moreOptions"/>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {computed, onMounted, ref} from "vue";
import Categories from "../components/main/Categories";
import Packages from "../components/main/Packages";
import {useStore} from "vuex";
import Loading from "../components/Loading";
import MoreOptions from "@/components/main/MoreOptions";
import {numberWithCommas} from "@/js/number";

export default {
  name: "Home",
  components: {MoreOptions, Loading, Packages, Categories},
  setup() {
    const FA_LAN = persian;
    const category = ref(true);
    const packages = ref(false);
    const moreOptions = ref(false);
    const store = useStore()
    const loading = ref(true);
    const walletAmount = computed(() => store.getters["wallet/get"]);

    onMounted(() => {
      fetch();
    })

    function showCategories() {
      category.value = true;
      packages.value = false;
      moreOptions.value = false;
    }

    function showPackages() {
      category.value = false;
      packages.value = true;
      moreOptions.value = false;
    }

    function showMore() {
      category.value = false;
      packages.value = false;
      moreOptions.value = true;
    }

    async function fetch() {
      await store.dispatch('packages/fetch');
      await store.dispatch('categories/fetch');
      loading.value = false
    }

    function withComma(number) {
      if (number === null || number === undefined) {
        return 0
      }
      return numberWithCommas(number)
    }

    return {
      FA_LAN,
      category,
      moreOptions,
      packages,
      loading,
      walletAmount,
      showCategories,
      showPackages,
      showMore,
      withComma
    }
  }
}
</script>

<style scoped>

</style>