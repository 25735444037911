<template>
  <div class="mt-2 d-flex justify-content-between bg-white shadow-sm rounded">
    <div><p class="m-0 p-3">
      {{ planDetail.main_category_title }} <span class="secondary_text">{{ planDetail.sub_category_title }}</span>
    </p></div>
    <div>
      <p class="m-0 p-3 orange-text">{{ planDetail.amount_dollar }} {{ FA_LAN.CRYPTO.DOLLAR }}</p>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";

export default {
  name: "CryptoPlanDetail",
  props: ['planDetail'],
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>