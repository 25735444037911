const wallet = {
    CREDIT: "اعتبار: ",
    WALLET_TITLE: "اعتبار",
    USABLE_CREDIT: "اعتبار قابل استفاده: ",
    WALLET_AMOUNT: "مجموع اعتبار شما",
    TRANSACTION_LIST: "لیست تراکنش ها",
    WHAT_IS_WALLET: "اعتبار چیست و چه کاربردی دارد؟",
    WALLET_RULE_TITLE: "شرایط و قوانین اعتبار",
    WALLET_USE_RULE: "شرایط استفاده",
    WALLET_INCREASE_METHOD: "راه های افزایش اعتبار",
    ENTER_GIFT_CODE: "ورود کد اعتبار هدیه",
    GIFT_CODE_DESCRIPTION: "کد اعتبار هدیه از درگاه‌های مختلفی در اختیار شما قرار می‌گیرد. مثل سایت‌ها و شرکت‌های طرف قرارداد، کدهای هدیه استوری اینستاگرام و ...",
    GIFT_CODE: "کد هدیه",
    SUBMIT_CODE: "ثبت کد",
    CODE_NOT_ENTER: "کد اعتبار هدیه وارد نشده است.",
    EMPTY_MY_WALLET:"هنوز اعتباری کسب نکردید",
}

export default wallet