<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="title"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <p v-if="empty" class="mt-3">{{ FA_LAN.BODY.EMPTY }}</p>
      <div v-else ref='scrollComponent'>
        <div v-for="review in reviews" :key="review.id" class="bg-white rounded shadow-sm pe-2 ps-2 pt-3 pb-3 mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img class="icon" src="../assets/icons/people-blue.png"/>
              <p class="small text-muted m-0 ms-2 ltr"> {{ review.phone }}</p>
            </div>
            <div class="d-flex align-items-center">
              <i v-for="index in 5-parseInt(review.rate)" :key="index"
                 class="bi bi-star align-middle orange-text fs-6"></i>
              <i v-for="index in parseInt(review.rate)" :key="index"
                 class="bi bi-star-fill align-middle orange-text fs-6"></i>
            </div>
          </div>
          <p class="mb-1 mt-2" v-html="review.text"></p>
          <p class="m-0 small text-muted text-end ltr">{{ toJalali(review.created_at) }}</p>
          <div class="px-4" v-if="review.has_reply">
            <hr/>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <img class="icon" src="../assets/icons/people-purple.png"/>
                <p class="small text-muted m-0 ms-2"> {{ review.reply.name }}</p>
              </div>
            </div>
            <p class="mb-1 mt-2" v-html="review.reply.text"></p>
          </div>
        </div>
      </div>
      <Loading :loading="loading"/>
    </div>
    <footer class="footer mt-auto fixed-bottom text-center">
      <div class="container py-1 bg-light all-card-size">
        <p class="purple-text mb-1 mt-1">
          {{ FA_LAN.REVIEW.REVIEW_SUBMIT_ONLY_IN_ANDROID }}
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {computed, onMounted, onUnmounted, ref} from "vue";
import persian from "@/fa-IR/fa-ir";
import PageHeader from "@/components/PageHeader";
import {useStore} from "vuex";
import {post} from "@/store/mainRoute";
import Loading from "@/components/Loading";
import {gregorian_to_jalali} from "../js/date/convert";

export default {
  name: "ShowReview",
  components: {PageHeader, Loading},
  setup() {
    const FA_LAN = persian;
    const route = useRoute();
    const store = useStore();
    const code = computed(() => route.params.code)
    const category = ref(null);
    const title = ref(null);
    const index = ref(0);
    const reviews = ref([])
    const empty = ref(false)
    const scrollComponent = ref(null);
    const loading = ref(false);

    onMounted(async () => {
      if (store.getters["packages/all"].length === 0 || store.getters["categories/all"].length === 0) {
        loading.value = true;
        await fetch();
      }

      getTitle()
      await getReviews();

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    function findPackageOrCategory() {
      category.value = store.getters["categories/category"](code.value) ? store.getters["categories/category"](code.value) :
          store.getters["packages/package"](code.value)
    }

    function getTitle() {
      findPackageOrCategory();
      title.value = FA_LAN.REVIEW.REVIEW_PAGE_TITLE.replace('{title}', category.value.title)
    }

    async function fetch() {
      await store.dispatch('packages/fetch');
      await store.dispatch('categories/fetch');
    }

    async function getReviews() {
      loading.value = true;
      await post(`v1/review/getAll?index=${index.value}`, {
        main_category: code.value,
      }).then(function (response) {
        if (response.data.code === 200) {
          reviews.value.push(...response.data.data.reviews);

        }
      })
      loading.value = false;
      checKEmptyObject();
    }

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return;
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - reviews.value.length > 10) { //for stop looping
          return;
        }

        index.value += 10;
        await getReviews();
      }
    }

    function checKEmptyObject() {
      empty.value = reviews.value.length === 0;
    }

    function checkPreviousReceive() {
      return reviews.value.length === index.value + 10;
    }

    function toJalali(date) {
      if (date) {
        return gregorian_to_jalali(date);
      }
    }

    return {FA_LAN, title, scrollComponent, reviews, loading, toJalali}
  }
}
</script>

<style scoped>
.icon {
  width: 24px;
  height: auto;
}

.ltr {
  direction: ltr;
}

i{
  margin-right: .15rem;
}
</style>