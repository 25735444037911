<template>
  <!--  loader-->
  <!--  <div v-if="loading" class="d-flex justify-content-center align-items-center min-vh-100">-->
  <!--    <div class="spinner-border spinner-border-large" role="status"></div>-->
  <!--  </div>-->
  <!--  pages after load-->
  <router-view v-slot="{ Component }">
    <transition name="slide-fade">
      <component :is="Component"/>
    </transition>
  </router-view>
  <!--  footer-->
  <Off :off="off" :source="key" :key="key"/>
  <PWABanner/>
  <Footer/>
</template>

<script>

import {useStore} from "vuex";
import {onMounted, ref} from "vue";
import Footer from "./components/Footer";
import {preventInspect} from "./js/protect/protectInspect";
import {post} from "./store/mainRoute";
import {useRoute, useRouter} from "vue-router";
import Off from "./components/convas/Off";
import PWABanner from "@/components/convas/PWABanner";

export default {
  name: 'App',
  components: {PWABanner, Off, Footer},
  setup() {
    // const loading = ref(true);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const off = ref(null);
    const key = ref(0);

    onMounted(async () => {
      preventInspect();

      await fetch();
      await checkActiveOff()
      await fetchSummaryReport()
      await getGroupPlan()
      await getWalletAmount()

    })

    router.beforeEach(async (to, from, next) => {
      if (from.name === "login") {
        key.value += 1;
        await checkActiveOff()
      }
      next()
    })

    async function fetch() {
      if (store.getters["apiKey/get"]) {
        await store.dispatch('apiKey/checkLogin');
      }
    }

    async function checkActiveOff() {
      if (route.name !== "plans") {
        await post('v1/hasActiveOff', null)
            .then(function (response) {
              if (response.data.code === 200) {
                off.value = response.data.data.offs
              }
            })
      }
    }

    async function fetchSummaryReport() {
      await store.dispatch('summaryReports/fetch');
    }

    async function getGroupPlan() {
      await store.dispatch('groupPlan/fetch');
    }

    async function getWalletAmount() {
      await store.dispatch('wallet/fetch');
    }

    return {off, key}
  }

}
</script>

<style>
.slide-fade-enter-active {
  transition: all .8s ease;
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.loader {
  left: 50%;
  margin-left: -4em;
}
</style>
