<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.MORE.ABOUT_US"/>
    </div>
    <div class="m-2">
      <div class="container all-card-size p-2 mt-4 mx-auto bg-white rounded shadow-sm">
        <h5 class="blue-text text-center">{{ FA_LAN.ABOUT_US.NAME }}</h5>
        <div class="text-center"><small>{{ FA_LAN.ABOUT_US.JOB }}</small></div>
        <hr>
        <div><small>{{ FA_LAN.ABOUT_US.RESUME_L1 }}</small></div>
        <br>
        <div><small>{{ FA_LAN.ABOUT_US.RESUME_L2 }}</small></div>
        <hr>
        <div class="text-center"><small>{{ FA_LAN.ABOUT_US.PROBLEM }}</small></div>
        <div class="d-flex justify-content-center mt-4">
          <div>
            <a href="https://t.me/excel_115" class="text-muted text-decoration-none" target="_blank">
              <div class="d-flex align-items-center">
                <p class="m-0">excel_115</p><h5 class="m-0"><i class="bi bi-telegram ms-2"></i></h5>
              </div>
            </a>
          </div>
          <div class="ms-5">
            <a href="https://api.whatsapp.com/send?phone=+989365784923" class="text-muted text-decoration-none"
               target="_blank">
              <div class="d-flex align-items-center">
                <p class="m-0">4923 578 0936</p><h5 class="m-0"><i class="bi bi-whatsapp ms-2"></i></h5>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import persian from "../fa-IR/fa-ir";

export default {
  name: "AboutUs",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>