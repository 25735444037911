<template>
  <div class="mb-4 pb-4">
    <div v-for="item in packages" :key="item.id" class=" mb-2 pt-2 pe-0 ps-0 bg-white shadow-sm rounded">
      <div class="row pe-0 col-12">
        <div class="col-4 col-md-3 d-flex ps-3 pe-3 justify-content-center align-content-center border-secondary border-end">
          <img :src="item.logo" class="col-12 col-md-10">
        </div>
        <div class="col-8 col-md-9 ps-4 pe-0">
          <div class="col-12">
            <p class="mb-2 pt-2">{{ item.title }}</p>
            <div><small class="secondary_text">{{ item.subtitle }}</small></div>
          </div>
        </div>
      </div>
      <div class="col-12 row p-0 ms-0 mt-1">
        <div class="col-4 col-md-3 text-center">
          <div class="col-11 buy-count-style small">
            <small> <i class="bi bi-person-check-fill me-1"></i>
              {{ withComma(item.buy_count) }}{{ FA_LAN.MAIN.STUDENT }}</small>
          </div>
        </div>
        <div class="col-8 col-md-9 text-end pe-0">
          <router-link v-if="item.has_access" class="btn btn-sm purple-background "
                       :to="{name:'educationList', params:{code:item.code,type:'packages'}}">
            <small> {{ FA_LAN.MAIN.SHOW_EDUCATION }}</small>
          </router-link>
          <router-link v-else class="btn btn-sm purple-background"
                       :to="{name:'packageInfo', params:{code:item.code}}">
            <small>{{ FA_LAN.MAIN.BUY_PACKAGES }}</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import persian from "../../fa-IR/fa-ir";
import {numberWithCommas} from "@/js/number";

export default {
  name: "packages",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const packages = computed(() => store.getters["packages/all"]);

    function withComma(number) {
      if (number === null || number === undefined) {
        return 0
      }
      return numberWithCommas(number)
    }

    return {packages, FA_LAN, withComma}
  }
}
</script>

<style scoped>
img {
  max-width: 80% !important;
  width: 80% !important;
  height: 85% !important;
  max-height: 85% !important;
}
</style>