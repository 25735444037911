import Swal from "sweetalert2"
import persian from "@/fa-IR/fa-ir";

export function sweetAlert(type, message, unLimit = false) {

    if (message == "Error: Network Error") { //if no internet access
        message = persian.MAIN.NO_INTERNET;
    }

    return Swal.fire({
        text: message,
        icon: type,
        position: "top",
        showConfirmButton: false,
        toast: true,
        timer: unLimit ? 20000 : 3000,
    })

}