<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="cryptoPayed">
    <div class="justify-content-center pt-3 pb-3">
      <div class="all-card-size mx-auto">
        <h5 class="offcanvas-title text-center purple-text mt-2" id="offcanvasBottomLabel">
          {{ FA_LAN.CRYPTO.TANKS }}</h5>
        <hr class="text-center">
        <p>{{ FA_LAN.CRYPTO.SUBMIT_DESCRIPTION }}</p>
        <hr class="text-center">
        <p>{{ FA_LAN.CRYPTO.TRACKING }}</p>
        <hr class="text-center">
        <div class="text-center mb-3 mt-2">
          <p class="mb-2">{{ FA_LAN.CRYPTO.ORDER_ID }}</p>
          <h6 class="orange-text">{{ orderId }}</h6>
        </div>
        <div class="d-grid gap-2 col-md-8 mx-auto">
          <router-link type="button" class="btn btn blue-background text-white" :to="{name:'home'}">
            {{ FA_LAN.CRYPTO.OK }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";

export default {
  name: "CryptoPayed",
  props: ['orderId'],
  setup() {
    const FA_LAN = persian

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>