<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" :id="id" aria-labelledby="offcanvasBottomLabel">
    <div class="justify-content-center pt-3 pb-3">
      <div class="all-card-size mx-auto">
        <h5 class="offcanvas-title text-center purple-text" id="offcanvasBottomLabel">
          {{ text }}</h5>
        <hr class="text-center">
        <div class="text-center">
          <img class="col-md-5" :src="'https://qr-code.ir/api/qr-code/?d='+url"/>
        </div>
        <div class="d-grid gap-2 col-md-8 mx-auto">
          <button class="btn btn blue-background text-white" data-bs-dismiss="offcanvas" aria-label="Close">
            {{ FA_LAN.APP_SETUP.CLOSE }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";

export default {
  name: "QR",
  props: ["url", 'id', 'text'],
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>