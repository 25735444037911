import {post} from "../mainRoute";

export const wallet = {
    namespaced: true,
    state: {
        total: 0
    },
    mutations: {
        set(state, total) {
            state.total = total
        }
    },
    getters: {
        get(state) {
            return state.total
        }
    },
    actions: {
        async fetch(context) {
            await post('v1/wallet/getUserTransactionList', null).then(function (response) {
                if (response.data.code === 200) {
                    context.commit('set', response.data.data.total)
                }
            })
        }
    }
}