<template>
  <div>
    <div v-if="!loading">
      <div class="mt-2 p-3 bg-white shadow-sm rounded">
        <div><p>{{ FA_LAN.CRYPTO.CHOOSE_CURRENCY }}</p></div>
        <div class="row ">
          <div v-for="currency in planDetail.available_currencies" :key="currency.id"
               class="col-6 col-md-4 p-1">
            <button class="row m-0 p-1 p-md-2 align-items-center crypto-name-style"
                    :class="{'crypto-active':isActive(currency.symbol)}" @click="selectCurrency(currency.symbol)">
              <div class="col-4 col-md-3"><img :src="currency.logo"></div>
              <div class="col-8 col-ms-9"><p class="m-0 small">{{ currency.name_fa }}</p></div>
            </button>
          </div>
        </div>
      </div>
      <div>
        <p class="mt-3">{{ FA_LAN.CRYPTO.NOTICE }}</p>
        <p class="small secondary_text">{{ FA_LAN.CRYPTO.NOTE_P1_1 }}</p>
        <p class="small secondary_text">{{ FA_LAN.CRYPTO.NOTE_P1_2 }}</p>
        <p class="small secondary_text">{{ FA_LAN.CRYPTO.NOTE_P1_3 }}</p>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-5 col-12">
        <button class="btn blue-background text-white col-md-8 col-12" @click="getPayInformation()">
          {{ FA_LAN.CRYPTO.GET_PAY_INFORMATION }}
        </button>
      </div>
    </div>
    <Loading :loading="loading"/>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import {onMounted, reactive, ref} from "vue";
import {post} from "@/store/mainRoute";
import Loading from "@/components/Loading";
import {sweetAlert} from "@/js/alert/sweetAlert";

export default {
  name: "ChooseCurrency",
  components: {Loading},
  props: ['planId','type'],
  setup(props, {emit}) {
    const FA_LAN = persian;
    const loading = ref(true);
    const data = reactive({
      type: props.type,
      id: props.planId
    })
    const planDetail = ref(null);
    const selectedCurrency = ref(null);

    onMounted(async () => {
      await getPlanDetail();
    })

    async function getPlanDetail() {
      await post('v1/crypto/getPlanDetail', data)
          .then(function (response) {
            if (response.data.code === 200) {
              planDetail.value = response.data.data.plan_detail
              emit('planDetail', planDetail);
            }
          })

      loading.value = false;
    }

    function isActive(currency) {
      return selectedCurrency.value === currency.toLowerCase();
    }

    function selectCurrency(currency) {
      selectedCurrency.value = currency.toLowerCase();
    }

    function getPayInformation() {
      if (!selectedCurrency.value) {
        sweetAlert('warning', FA_LAN.CRYPTO.CURRENCY_NOT_SELECTED)
        return;
      }
      emit('currency', selectedCurrency);
    }

    return {FA_LAN, loading, planDetail, isActive, selectCurrency, getPayInformation}
  }
}
</script>

<style scoped>

</style>