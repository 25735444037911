<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.IPG_PAY.TITLE"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size">
      <Loading v-if="!payDetail"/>
      <div v-else>
        <div class="mt-2 d-flex justify-content-between bg-white shadow-sm rounded">
          <div><p class="m-0 p-3">
            {{ payDetail.main_category_title }} <span class="secondary_text">{{ payDetail.sub_category_title }}</span>
          </p></div>
          <div>
            <p class="m-0 p-3 orange-text">{{ withComma(payDetail.amount) }} {{ FA_LAN.PLANS.TOMAN }}</p>
          </div>
        </div>
        <p class="mt-3 mb-0">{{ FA_LAN.WALLET.CREDIT }}</p>
        <div class="mt-2 p-3 bg-white shadow-sm rounded text-center">
          <p class="m-0">
            <span class="orange-text">{{ withComma(payDetail.wallet_use) }}{{ FA_LAN.PLANS.TOMAN }}</span>
            {{ FA_LAN.IPG_PAY.PAYED_BY_CREDIT }}
          </p>
        </div>
        <p v-if="payDetail.is_max" class="mt-2 text-muted">{{ getWalletLimitedText() }}</p>
        <div class="d-flex justify-content-center mt-5 mb-5 col-12">
          <button class="btn blue-background text-white col-md-8 col-12" @click="createUrl">
            {{ getPayBtnText() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import PageHeader from "@/components/PageHeader";
import {useRoute, useRouter} from "vue-router";
import {sweetAlert} from "@/js/alert/sweetAlert";
import {createPayLink, getDetail} from "@/js/pay/pay";
import {isDesktop} from "@/swichDevice";
import {onMounted, ref} from "vue";
import Loading from "@/components/Loading";
import {numberWithCommas} from "@/js/number";
import {useStore} from "vuex";

export default {
  name: "IPGPay",
  components: {Loading, PageHeader},
  setup() {
    const FA_LAN = persian;
    const route = useRoute();
    const planID = route.params.planId;
    const type = route.params.type;
    const router = useRouter();
    const payDetail = ref(null)
    const store = useStore();

    onMounted(async () => {
      await getPayDetail();
    })

    async function getPayDetail() {
      await getDetail(type, planID)
          .then(function (response) {
            if (response.data.code === 200) {
              payDetail.value = response.data.data.pay_detail

            } else {
              sweetAlert('error', response.data.description)
            }

          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }


    async function createUrl() {
      sweetAlert('success', FA_LAN.PAY.PENDING, true)

      await createPayLink(type, planID)
          .then(function (response) {
            if (response.data.code === 200) {
              updateWalletAmount();

              if (isDesktop) {
                window.open(response.data.data.url, 'newwindow',
                    'height=' + screen.height + ', width=' + screen.width);
              } else {
                window.location.href = response.data.data.url;
              }

            } else if (response.data.code === 201) {//no pay need
              sweetAlert('success', response.data.description)
              router.push({name: 'home'});

            } else {
              sweetAlert('error', response.data.description)
            }

          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    function withComma(number) {
      return numberWithCommas(number)
    }

    function getPayBtnText() {
      return FA_LAN.IPG_PAY.TO_PAY.replace("{amount}", withComma(payDetail.value.pay_amount))
    }

    function getWalletLimitedText() {
      return FA_LAN.IPG_PAY.PAY_LIMITED.replace("{percentage}", withComma(payDetail.value.max_wallet_use))
    }

    async function updateWalletAmount() {
      await store.dispatch('wallet/fetch');
    }

    return {FA_LAN, payDetail, getWalletLimitedText, getPayBtnText, createUrl, withComma}
  }
}
</script>

<style scoped>

</style>