const appSetup = {
    TITLE: "نصب برنامه آموزش آفیس",
    ANDROID_GOOGLE: "نسخه اندروید - گوگل پلی",
    ANDROID_BAZAAR: "نسخه اندروید - کافه بازار",
    ANDROID_DIRECT: "نسخه اندروید - لینک مستقیم",
    IPHONE: "نسخه آیفون (PWA)",
    WEB: "نسخه وب / Web",
    WINDOWS: "نسخه ویندوز / Windows",
    LINUX: "نسخه لینوکس / Linux",
    QR_CODE: "مشاهده کد QR",
    CLOSE: "حله",
    DESCRIPTION: "برای مشاهده و دریافت نسخه مورد نظر، روی عکس آن کلیک کنید.",
    WHAT_IS_QR: "کد QR برای اسکن و ارسال سریع به گوشی موبایل می‌باشد.",
}

export default appSetup