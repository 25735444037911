import {post} from "../mainRoute";

export const groupPlan = {
    namespaced: true,
    state: {
        groupPlan: {},
    },
    mutations: {
        set(state, detail) {
            state.groupPlan = detail
        }
    },
    getters: {
        get(state) {
            return state.groupPlan
        }
    },
    actions: {
        async fetch(context) {
            await post('v1/groupPlan/getDetail', null)
                .then(function (response) {
                    if (response.data.code === 200) {
                        context.commit('set', response.data.data.details);
                    }
                })
        }
    }
}